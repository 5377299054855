import React, { useCallback, useMemo } from 'react'
import { useStore } from '../../store/store';
import { contentHeight, defaultZoom, dynamicZoom, ItemTypes, pageBackground } from '../../constants/constants';
import { pageHeight, pageWidth } from '../../constants/gridConfig';
import ItemWrapper from '../Container/ItemWrapper';
import { Virtuoso } from 'react-virtuoso';
import { isATitleBox } from '../../utils/boxes';
import _ from 'lodash';
import { useContainersToShow } from '../../hooks';

const selector = ({
  configuration,
  landscape,
  boxes,
}) => {

  return {
    configuration,
    landscape,
    boxes,
  }
};

const listRef = React.createRef();
export const scrollToItem = (box, containers) => {
  const containerIdx = containers.findIndex((c) => c.columnsIds.includes(box.columnId))
  listRef.current.scrollToIndex(containerIdx + 1);
};

const List = React.forwardRef((props, ref) => {
  const {
    configuration,
    landscape
  } = useStore(selector);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: 'center',
        marginLeft: 0,
      }}
      data-role={pageBackground}>
      <div
        {...props}
        ref={ref}
        style={{
          display: "flex",
          backgroundColor: "white",
          flexDirection: "column",
          position: 'relative',
          width: landscape ? pageHeight : pageWidth,
          color: configuration.baseFontColor?.style,
          ...props.style
        }}
        className="page"
        data-role={pageBackground} />
    </div>
  )
})

const InfinitePage = () => {
  const {
    boxes,
    configuration,
  } = useStore(selector);

  const containersToShow = useContainersToShow()

  const findBoxeByColumnId = useCallback((columnId) => {
    return boxes.find((b) => b.columnId === columnId)
  }, [boxes])

  const formatContainer = useCallback(() => {
    const titleIndices = {
      [ItemTypes.TITLE]: 0,
      [ItemTypes.TITLE_2]: 1,
      [ItemTypes.TITLE_3]: 2,
      [ItemTypes.TITLE_4]: 3,
    };
    let skip = false;
    let titleLevel;
    return containersToShow.map((container) => {
      const box = findBoxeByColumnId(container.columnsIds[0])
      if (isATitleBox(box?.type) && box.collapsed === true) {
        if (titleIndices[box?.type] > titleLevel) return { type: 'title_container_hidden', id: container.id };
        skip = true;
        titleLevel = titleIndices[box?.type];
        return { type: 'container', id: container.id, titleCollapsed: true };
      }
      if (isATitleBox(box?.type) && titleIndices[box?.type] <= titleLevel) {
        skip = false;
      }
      if (skip) {
        return { type: 'title_container_hidden', id: container.id };
      }
      if (box?.type !== ItemTypes.SECTION_LINK) {
        return { type: 'container', id: container.id };
      }
      if (box?.type === ItemTypes.SECTION_LINK) {
        // Important for keeping th correct order of the containers
        return { type: 'section_link_anchor_container_hidden', id: null };
      }

      return { type: 'container', id: container.id }
    })
  }, [containersToShow, findBoxeByColumnId])


  const items = useMemo(() => {
    return [
      { type: 'adder-menu', id: 0 },
      ..._.compact(formatContainer()),
      { type: 'infinite-page-drop', id: 0 },
    ]
  }, [formatContainer]);

  return (
    <Virtuoso
      ref={listRef}
      style={{
        height: contentHeight * (1 / ((configuration.zoom || dynamicZoom() || defaultZoom) / 100)),
        width: '100%',
        zoom: `${configuration.zoom || dynamicZoom() || defaultZoom}%`
      }}
      className='no-scrollbar'
      totalCount={items.length}
      itemContent={index => <ItemWrapper
        type={items[index].type}
        titleCollapsed={items[index].titleCollapsed}
        index={index}
        itemId={items[index].id}
        itemLength={items.length}
      />}
      defaultItemHeight={contentHeight}
      computeItemKey={(index) => {
        return `${items[index].type}-${items[index].id}`
      }}
      components={{ List }}
    />
  )
}

export default InfinitePage;
