import React, { useMemo } from 'react';

// ANT Design
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from '../../store/store';
import { useOverlay } from '../../contexts/OverlayContext';
import { hasVariableBoxError } from '../../utils/boxes';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { sortAndFilterVariablesGroupedByContainer } from '../../utils/variableOrderArray';
import { getPrimaryDark } from '../../constants/constants';

const LeftSideButton = ({ variablesGroupedByContainer }) => {
  const { t } = useTranslation();

  const variableOrderArray = useStore((state) => state.variableOrderArray)
  const currentProposal = useStore((state) => state.currentProposal)
  const maskEditing = useStore(({ maskEditing }) => maskEditing)
  const currentDocument = useStore(({ currentDocument }) => currentDocument)
  const currentTemplate = useStore(({ currentTemplate }) => currentTemplate)

  const { overlayProps, updateLeftShownStatus } = useOverlay();

  const hasVariableErrors = useMemo(() => {
    return currentProposal?.id && variablesGroupedByContainer
      .map((vB) => vB.variablesBoxes.filter((v) => !v.notUsed)).flat()
      .some((b) => hasVariableBoxError(b).error)
  }, [currentProposal?.id, variablesGroupedByContainer])

  const shownStatus = overlayProps.left.shownStatus

  const changeStatus = () => {
    const newStatus = shownStatus === "summary" ? "variables" : "summary"
    const usedVariablesGroupedByContainer = sortAndFilterVariablesGroupedByContainer(variablesGroupedByContainer, variableOrderArray)
    const boxId = newStatus === "summary" || usedVariablesGroupedByContainer.length < 1 ?
      undefined : usedVariablesGroupedByContainer[0].variablesBoxes[0].id
    updateLeftShownStatus(newStatus, boxId);
  }

  const getTitle = () => {
    if (shownStatus === "summary") {
      return t('variable-handler')
    } else {
      if (maskEditing?.length > 0) {
        return t('main-mask')
      }
      return t('main-document')
    }
  }

  return (
    <div style={{
      position: 'sticky',
      top: 0,
      backgroundColor: getPrimaryDark(currentDocument.type === 'template'),
      zIndex: 999,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Button
        onClick={changeStatus}
        icon={shownStatus === "summary" ? null : <FontAwesomeIcon icon={icon({ name: 'arrow-left', style: 'solid' })} style={{ marginRight: "8px" }} />}
        style={{
          width: "100%",
          padding: '4px 10px',
          margin: '10px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        type='primary'
      >
        <span style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>{getTitle()}</span>
      </Button>
      <div style={{
        fontWeight: 'bold',
        display: shownStatus !== "summary" ? 'none' : 'flex',
        gap: 8,
        alignItems: 'center',
        color: 'white',
        visibility: shownStatus === "summary" ? 'visible' : 'hidden'
      }}>
        {hasVariableErrors && <>
          <FontAwesomeIcon
            icon={icon({ name: 'exclamation-triangle', style: 'duotone', })}
            style={{
              fontSize: 36,
              "--fa-primary-color": "black",
              "--fa-secondary-color": "yellow",
              "--fa-secondary-opacity": 1
            }}
          />
          Le formulaire possède des champs vides.
        </>}
        {currentDocument.type === 'template' && currentTemplate?.name && <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 15,
            color: "#b8b8b8",
            fontStyle: 'italic'
          }}>
          <div
            style={{
              fontSize: 18,
            }}>
            {currentTemplate?.pattern === 'section' ? 'Section' : 'Modèle'}
          </div>
          <div style={{
            fontWeight: 'normal'
          }}>
            "{currentTemplate?.name}"
          </div>
        </div>}
      </div>
    </div>
  );
};

export default LeftSideButton;
