import { message } from 'antd';
import { produce } from 'immer';
import {
  defaultConfiguration,
  defaultItemValues,
  defaultMasks,
  dynamicZoom,
  ItemTypes,
} from '../constants/constants';
import { calculateLeftPadding, calculateWidth } from '../constants/gridConfig';
import i18n from '../i18n';
import * as documentDatasApi from '../requests/documentDatas';
import * as proposalsApi from '../requests/proposals';
import * as templatesApi from '../requests/templates';
import * as userApi from '../requests/user';
import {
  isAVariableBox,
  mutateBoxesOrderInfinite,
  mutateRemoveBox,
} from '../utils/boxes';
import { emptyUrl, getDocumentFromParams, updateUrl } from '../utils/browser';
import {
  formatResContent,
  formatTemplatePattern,
  formatVariables,
  syncStructure,
} from '../utils/dataSync';
import { mutateResizeSummary, mutateTitlesIndexes } from '../utils/titles';
import {
  mutateExecuteParentTriggers,
  mutateRemoveDeletedSectionTriggers,
  mutateSearchAndLaunchTrigger,
} from '../utils/triggers';
import { getTemplateSections } from '../requests/sections';
import { fillEmptyAdminTablesBoxes } from '../utils/table';
import { mutateInjectSection } from '../utils/sections';
import { mutateRemoveContainer } from '../utils/containers';

export const defaultDocContent = {
  configuration: { ...defaultConfiguration },
  summaryConfiguration: {
    ...defaultItemValues.summary.content.configuration,
  },
  gridConfiguration: { ...defaultItemValues },
  // pages: [newPage()],
};

export const apiActions = (set, get) => ({
  apiError: (error) => {
    emptyUrl();
    set(
      produce((state) => {
        state.currentProposal = { id: null };
        state.currentDocument = getDocumentFromParams();
      }),
      false,
      `API error : ${error.message}...`
    );
  },
  startLoading: () => {
    set(
      produce((state) => {
        state.loadingData = true;
      }),
      false,
      'Set loading data true'
    );
  },
  stopLoading: () => {
    set(
      produce((state) => {
        state.loadingData = false;
      }),
      false,
      'Set loading data false'
    );
  },
  fetchDatas: async () => {
    // Get User
    get().startLoading();
    const connectedUser = await userApi.getUser();
    if (connectedUser) {
      set(
        produce((state) => {
          state.user = { ...state.user, ...connectedUser.data };
        }),
        false,
        'Set Connected User'
      );
    } else {
      get().stopLoading();
      message.error("Problème d'authentification", 0);
      return;
    }
    const currentDocument = get().currentDocument;
    if (!currentDocument.id) {
      get().stopLoading();
      message.error('Choisissez un document');
      return;
    }
    // TID
    if (currentDocument.type === 'template') {
      await get().applyTemplate(currentDocument.id);
      get().stopLoading();
      return;
    }
    await get().fetchTables();
    await get().fetchDefaultVariablesApi();

    let res = await proposalsApi.fetchProposals(
      currentDocument,
      get().variables
    );
    set(
      produce((state) => {
        state.proposals = res;
      }),
      false,
      `Populated proposal list`
    );

    if (res) {
      const currentProposal = get().currentProposal;
      if (currentProposal.id) {
        if (res.some((proposal) => proposal.id === currentProposal.id)) {
          const fetchedProposal = await proposalsApi.fetchProposal(
            { id: currentProposal.id, type: currentDocument.type },
            get().defaultVariablesApi
          );
          await get().applyProposal(fetchedProposal, currentDocument.type);
        } else {
          updateUrl({ pid: null });
          set(
            produce((state) => {
              state.currentProposal = { id: null };
              state.proposalList = true;
            }),
            false,
            `Remove pid`
          );
        }
      }
    } else {
      get().stopLoading();
    }
  },

  deleteProposalStore: (value) => {
    set(
      produce((state) => {
        let index = state.proposals.findIndex((obj) => obj.id === value);
        state.proposals.splice(index, 1);
      }),
      false,
      `Deleted Proposal of id ${value}`
    );
  },

  setLoaderPdfToImage: (value) => {
    set(
      produce((state) => {
        if (value === true) {
          state.boxVariableSelectedId = null;
        }
        state.loaderPdfToImage = value;
      }),
      false,
      `setLoaderPdfToImage to ${value}`
    );
  },

  deleteTemplateStore: (value) => {
    set(
      produce((state) => {
        let index = state.templates.findIndex((obj) => obj.id === value);
        state.templates.splice(index, 1);
      }),
      false,
      `Deleted Template of id ${value}`
    );
  },

  createProposalStore: (value) => {
    set(
      produce((state) => {
        state.proposals.push(value);
      }),
      false,
      `Added ${value.name} to proposals`
    );
  },

  renameProposalStore: (value) => {
    set(
      produce((state) => {
        let index = state.proposals.findIndex((obj) => obj.id === value.id);
        state.proposals[index].name = value.name;
      }),
      false,
      `Changed ${value.id}'s name to ${value.name}`
    );
  },

  updateTemplateInfosStore: (value) => {
    set(
      produce((state) => {
        let index;
        if (value.pattern !== 'section') {
          index = state.templates.findIndex((obj) => obj.id === value.id);
          state.templates[index].name = value.name;
          state.templates[index].document_type_ids = value.document_type_ids;
          if (
            state.currentTemplate &&
            state.currentTemplate.id === state.templates[index].id
          ) {
            state.currentTemplate = {
              ...state.currentTemplate,
              ...state.templates[index],
            };
          }
        } else {
          index = state.sections.findIndex((obj) => obj.id === value.id);
          state.sections[index].name = value.name;
          state.sections[index].document_type_ids = value.document_type_ids;
          if (
            state.currentTemplate &&
            state.currentTemplate.id === state.sections[index].id
          ) {
            state.currentTemplate = {
              ...state.currentTemplate,
              ...state.sections[index],
            };
          }
        }
      }),
      false,
      `Changed ${value.id}'s name to ${value.name}`
    );
  },

  duplicateTemplateStore: (value) => {
    set(
      produce((state) => {
        if (value.pattern !== 'section') {
          state.templates.push(value);
        } else {
          state.sections.push(value);
        }
      }),
      false,
      `Duplicated ${value}`
    );
  },

  duplicateProposalStore: (value, document_type_ids) => {
    set(
      produce((state) => {
        state.proposals.push({ ...value, document_type_ids });
      }),
      false,
      `Duplicated ${value} to proposals`
    );
  },

  addVariablePathToBox: (id, value) => {
    set(
      produce((state) => {
        state.boxes.find((b) => b.id === id).variablePath = value;
      }),
      false,
      `Added variablePath ${value}to box ${id}`
    );
  },
  handleProposalList: (value) => {
    if (value === true) {
      set(
        produce((state) => {
          state.selectedBoxId = null;
        }),
        false,
        `Unselect Box`
      );
    }
    set(
      produce((state) => {
        state.copy = null;
        state.proposalList = value;
      }),
      false,
      `Changed proposalList to ${value}`
    );
  },
  handleMaskList: (value) => {
    if (value === true) {
      set(
        produce((state) => {
          state.selectedBoxId = null;
        }),
        false,
        `Unselect Box`
      );
    }
    set(
      produce((state) => {
        state.maskList = value;
      }),
      false,
      `Changed maskList to ${value}`
    );
  },

  /* VARIABLES */
  fetchDefaultVariablesApi: async () => {
    const { id, type } = get().currentDocument;
    if (!id) return;
    const res = await documentDatasApi.getVariables({ id, type });
    set(
      produce((state) => {
        state.defaultVariablesApi = res;
      }),
      false,
      'fetchDefaultVariablesApi'
    );
  },
  fetchTable: async (tableName, proposal = null) => {
    const currentDocument = proposal ? proposal : get().currentDocument;
    if (currentDocument.type === 'template' || !tableName) return;
    const res = await documentDatasApi.getTable(currentDocument, tableName);
    return res;
  },
  /**
   * Proposal api
   */
  saveAndGeneratePdf: async (isArchive) => {
    const {
      boxes,
      pages,
      masks,
      configuration,
      summaryConfiguration,
      gridConfiguration,
      currentDocument,
      currentProposal,
      containers,
      columns,
      proposals,
      landscape,
      variables,
      templates,
      sections,
      variableGroups,
      variableOrderArray,
      triggers,
    } = get();
    if (!currentDocument.id) return;
    set(
      produce((state) => {
        state.PDFRenderState = isArchive ? 'archive' : 'visualize';
      }),
      false,
      'saveAndGeneratePdf'
    );
    let name;
    let res;
    if (currentDocument.type === 'template') {
      const templatesAndSections = templates.concat(sections);
      let index = templatesAndSections.findIndex(
        (obj) => obj.id === currentDocument.id
      );
      name = templatesAndSections[index].name;
      res = await templatesApi.postTemplateAndFetchPdf({
        currentDocument,
        boxes,
        pages,
        masks,
        configuration,
        summaryConfiguration,
        gridConfiguration,
        name,
        containers,
        columns,
        landscape,
        isArchive,
        variables,
        variableGroups,
        variableOrderArray,
        triggers,
      });
    } else {
      let index = proposals.findIndex((obj) => obj.id === currentProposal.id);
      name = proposals[index].name;
      res = await proposalsApi.postProposalAndFetchPdf({
        currentDocument,
        currentProposal,
        boxes,
        pages,
        masks,
        configuration,
        summaryConfiguration,
        gridConfiguration,
        name,
        containers,
        columns,
        landscape,
        isArchive,
        variables,
        variableGroups,
        variableOrderArray,
        triggers,
      });
    }
    if (!res.ok) {
      message.error('Une erreur est survenu lors de la génération du PDF');
      set(
        produce((state) => {
          state.PDFRenderState = false;
        }),
        false,
        'saveAndGeneratePdf'
      );
    }
    if (process.env.REACT_APP_LOCAL_PDF_URL) {
      set(
        produce((state) => {
          state.PDFRenderState = false;
        }),
        false,
        'saveAndGeneratePdf'
      );
    }
    return res;
  },

  setStore: async (newStore) => {
    set(
      produce((state) => {
        Object.keys(newStore).forEach((k) => {
          state[k] = newStore[k];
        });
      }),
      false,
      'setStore'
    );
  },

  saveProposal: async () => {
    const {
      landscape,
      boxes,
      pages,
      masks,
      configuration,
      summaryConfiguration,
      gridConfiguration,
      variables,
      currentDocument,
      currentProposal,
      containers,
      columns,
      defaultVariablesApi,
      variableGroups,
      triggers,
    } = get();
    if (!currentDocument.id) return;

    const res = await proposalsApi.postProposal({
      currentDocument,
      currentProposal,
      landscape,
      boxes,
      pages,
      masks,
      configuration: (({ zoom, ...restConfiguration }) => restConfiguration)(
        configuration
      ),
      summaryConfiguration,
      gridConfiguration,
      containers,
      columns,
      variables,
      variableGroups,
      triggers,
    });
    res.content = formatResContent(
      res.content,
      defaultVariablesApi,
      false,
      i18n.t,
      landscape
    );
    const { id } = res;
    set(
      produce((state) => {
        state.currentProposal = { id };
        Object.keys(res.content).forEach((k) => {
          state[k] = res.content[k];
        });
      }),
      false,
      'saveProposal'
    );
    return res;
  },

  newProposal: async (document) => {
    await get().fetchTables(document.documentType, document.id);
    set(
      produce((state) => {
        state.currentProposal = { id: null };
        state.currentDocument = document;
      }),
      false,
      'New Proposal (have to choose template)'
    );
  },

  changeCurrentTab: async (currentTab) => {
    set(
      produce((state) => {
        state.currentTab = currentTab;
      }),
      false,
      `Switched to tab ${currentTab}`
    );
  },

  setEventManagerEnabled: (value) => {
    set(
      produce((state) => {
        state.eventManagerEnabled = value;
      }),
      false,
      `Changed eventManagerEnabled to  ${value}`
    );
  },

  setProposal: async (id) => {
    const currentDocument = get().currentDocument;
    await get().fetchDefaultVariablesApi();
    let urlParams = {
      pid: id,
    };
    if (currentDocument.type === 'opportunity') {
      urlParams.oid = currentDocument.id;
    } else if (currentDocument.type === 'offer') {
      urlParams.ofid = currentDocument.id;
    } else {
      throw new Error(
        `Document type : ${currentDocument.type} is not handled by editor.`
      );
    }
    emptyUrl();
    updateUrl(urlParams);
    get().setProposalId(id);
    const fetchedProposal = await proposalsApi.fetchProposal(
      { id: id, type: currentDocument.type },
      get().defaultVariablesApi
    );
    await get().applyProposal(fetchedProposal, currentDocument.type);
  },

  applyProposal: async (data, type) => {
    let { id, content, work, landscape, has_opportunity_option, zone_names } =
      data;
    let contentExists = true;
    if (!content) {
      content = defaultDocContent;
      contentExists = false;
    }
    // content.configuration.fontFamily =
    //   content.configuration?.fontFamily ?? defaultConfiguration.fontFamily;
    get().loadFont(content.configuration.fontFamily);

    //Inject section if there are some in template
    let sectionBoxes;
    if (content.boxes.some((box) => box.type === ItemTypes.SECTION_LINK)) {
      sectionBoxes = content.boxes
        .filter((box) => box.type === ItemTypes.SECTION_LINK)
        .map((box) => ({
          sectionId: box.sectionId,
          boxId: box.id,
          columnId: box.columnId,
        }));
      await Promise.all(
        sectionBoxes.map(async (box) => {
          const section = await get().getTemplate(box.sectionId);
          if (!section) return;
          const currentDocument = get().currentDocument;
          const sectionContent =
            currentDocument.type === 'template'
              ? section.content
              : {
                  ...section.content,
                  boxes: await fillEmptyAdminTablesBoxes({
                    boxes: section.content.boxes,
                    proposable_id: currentDocument.id,
                    type: currentDocument.type,
                  }),
                };

          // Injection de la section dans le box
          box.sectionContent = sectionContent;
        })
      );
    }
    set(
      produce((state) => {
        state.currentProposal = state.currentDocument.id;
        state.landscape = landscape;
        state.hasOpportunityOption = !!has_opportunity_option;
        state.zoneNames = zone_names ?? [];
        Object.keys(content).forEach((k) => {
          state[k] = content[k];
        });
        if (contentExists) {
          state.configuration = syncStructure(
            content.configuration,
            defaultConfiguration
          );
          state.summaryConfiguration = syncStructure(
            content.summaryConfiguration,
            defaultItemValues.summary.content.configuration
          );
          state.gridConfiguration = syncStructure(
            content.gridConfiguration,
            defaultItemValues
          );
          // state.pages = syncPageStructure(content.pages);
          if (state.hasOwnProperty('pages')) {
            delete state.pages;
          }
        }

        // Injection of linked sections in state
        if (sectionBoxes) {
          sectionBoxes.forEach((box) => {
            mutateInjectSection({
              draft: state,
              sectionId: box.sectionId,
              boxId: box.boxId,
              sectionContent: box.sectionContent,
            });
            // Remove archor box and container after injection (to testing more)
            mutateRemoveBox(state, box.boxId);
            const containerId = state.containers.find((c) =>
              c.columnsIds.includes(box.columnId)
            ).id;
            mutateRemoveContainer(state, containerId);
          });
        }

        state.currentProposal = { id, work };
        // mutateBoxesPageIndex(
        //   state.boxes,
        //   state.pages.map((p) => p.id)
        // );
        mutateBoxesOrderInfinite(state);
        mutateTitlesIndexes(state);
        mutateResizeSummary(state);
        Object.entries(state.variables).forEach(([key, value]) => {
          if (!value.notUsed) {
            mutateSearchAndLaunchTrigger({
              triggerId: key,
              draft: state,
            });
          }
        });
        state.boxes.forEach((b) => {
          if (isAVariableBox(b.type)) {
            mutateExecuteParentTriggers({
              draft: state,
              key: b.id,
            });
            mutateSearchAndLaunchTrigger({
              triggerId: b.id,
              draft: state,
            });
          }
        });
      }),
      false,
      'applyProposal'
    );
    get().stopLoading();
  },

  setProposalId: (id) => {
    set(
      produce((state) => {
        state.currentProposal = { id: id };
      }),
      false,
      `Set proposal ID to ${id} to match PID`
    );
  },

  getTemplate: async (id) => {
    if (!get().defaultVariablesApi) {
      await get().fetchDefaultVariablesApi();
    }
    return await templatesApi.getTemplate(
      id,
      get().defaultVariablesApi,
      get().currentDocument.type === 'template'
    );
  },
  getTemplates: async () => {
    let res = null;
    if (get().currentDocument.type === 'template') {
      res = await templatesApi.getTemplates();
    } else if (get().currentDocument.type === 'opportunity') {
      res = await templatesApi.getOpportunityTemplates(
        get().currentDocument.id
      );
    } else {
      res = await templatesApi.getOfferTemplates(get().currentDocument.id);
    }

    if (!res) return;
    //séparation des Sections et des Templates pour simplifier l'affichage
    // set(produce((state) => {
    //   state.configuration.zoom= dynamicZoom();
    // }), false, 'setZoom');
    set(
      produce((state) => {
        state.sections = [];
        state.templates = [];
        res.forEach((element) => {
          if (!element.work) element.work = '';
          if (element.pattern === 'section') {
            state.sections.push(element);
          } else if (element.pattern === 'template') {
            state.templates.push(element);
          }
        });
      }),
      false,
      `Set sections and templates`
    );
  },
  deleteTemplate: async (id) => {
    await templatesApi.deleteTemplate(id);
    await get().getTemplates();
  },
  updateTemplate: async () => {
    const {
      landscape,
      currentTemplate,
      boxes,
      pages,
      masks,
      configuration,
      summaryConfiguration,
      gridConfiguration,
      containers,
      columns,
      variables,
      variableGroups,
      variableOrderArray,
      triggers,
    } = get();
    await templatesApi.updateTemplate({
      ...currentTemplate,
      landscape,
      boxes,
      pages,
      masks,
      configuration,
      summaryConfiguration,
      gridConfiguration,
      containers,
      columns,
      variables,
      variableGroups,
      variableOrderArray,
      triggers,
    });
  },
  postTemplate: async ({
    name,
    templatable,
    templatePattern,
    landscape = false,
    work,
    documentTypeIds,
  }) => {
    const {
      boxes,
      pages,
      masks,
      configuration,
      summaryConfiguration,
      gridConfiguration,
      containers,
      columns,
      variables,
      variableGroups,
      variableOrderArray,
      triggers,
    } = get();
    const res = await templatesApi.postTemplate({
      name,
      templatable,
      work,
      pattern: formatTemplatePattern(templatePattern),
      landscape,
      boxes,
      pages,
      masks,
      configuration,
      summaryConfiguration,
      gridConfiguration,
      containers,
      columns,
      documentTypeIds,
      variableGroups,
      variables,
      variableOrderArray,
      triggers,
    });
    if (!res) return;
    updateUrl({ tid: res.id });
    await get().fetchDefaultVariablesApi();
    set(
      produce((state) => {
        state.currentTemplate = {
          name: res.name,
          id: res.id,
          templatable: res.templatable,
          pattern: res.pattern,
          landscape: res.landscape,
          work: work,
          preview: res.preview,
          document_type_ids: res.document_type_ids,
        };
        state.work = res.work;
        state.landscape = res.landscape;
        state.currentProposal = { id: null };
        const { variables, variableGroups } = formatVariables({
          content: res.content,
          defaultVariablesApi: state.defaultVariablesApi,
          currentDocIsTemplate: true,
        });
        state.variables = variables;
        state.variableGroups = variableGroups;
      }),
      false,
      'postTemplate'
    );
    await get().getTemplates();
    await get().fetchTables('template', res.id);
  },
  // patchTemplate: async (id, value) => {
  //   const res = await templatesApi.patchTemplate(id, value);
  //   if (!res) return;
  //   await get().getTemplates();
  // },
  fetchTables: async () => {
    const { id, type } = get().currentDocument;
    if (!id) return;
    const res = await documentDatasApi.getTables(type, id);
    set(
      produce((state) => {
        state.tables = res;
      }),
      false,
      'fetchTables'
    );
  },
  applyTemplate: async (id) => {
    const res = await get().getTemplate(id);
    const { documentType, id: docId } = get().currentDocument;
    await get().fetchTables(documentType, docId);
    await get().fetchDefaultVariablesApi();
    if (res.error) {
      emptyUrl();
      set(
        produce((state) => {
          state.currentProposal = { id: null };
          state.currentDocument = getDocumentFromParams();
        }),
        false,
        `Template error : ${res.error.message}...`
      );
      return false;
    }
    res.content.configuration.fontFamily =
      res.content.configuration.fontFamily ?? defaultConfiguration.fontFamily;
    get().loadFont(res.content.configuration.fontFamily);
    const sections = await getTemplateSections(id);
    res.content.triggers = mutateRemoveDeletedSectionTriggers({
      triggers: res.content.triggers,
      sections,
    });

    //Inject section if there are some in template
    let sectionBoxes;
    if (res.content.boxes.some((box) => box.type === ItemTypes.SECTION_LINK)) {
      sectionBoxes = res.content.boxes
        .filter((box) => box.type === ItemTypes.SECTION_LINK)
        .map((box) => ({ sectionId: box.sectionId, boxId: box.id }));
      await Promise.all(
        sectionBoxes.map(async (box) => {
          const section = await get().getTemplate(box.sectionId);
          if (!section) return;
          const currentDocument = get().currentDocument;
          const sectionContent =
            currentDocument.type === 'template'
              ? section.content
              : {
                  ...section.content,
                  boxes: await fillEmptyAdminTablesBoxes({
                    boxes: section.content.boxes,
                    proposable_id: currentDocument.id,
                    type: currentDocument.type,
                  }),
                };

          // Injection de la section dans le box
          box.sectionContent = sectionContent;
        })
      );
    }

    set(
      produce((draft) => {
        mutateApplyTemplate(draft, res);
        // Injection of linked sections in state
        if (sectionBoxes) {
          sectionBoxes.forEach((box) => {
            mutateInjectSection({
              draft,
              sectionId: box.sectionId,
              boxId: box.boxId,
              sectionContent: box.sectionContent,
              linked_section: true,
            });
          });
        }
      }),
      false,
      'applyTemplateLayout'
    );
    set(
      produce((draft) => {
        mutateSetGridConfiguration(draft);
      }),
      false,
      'setGridConfiguration'
    );
    return true;
  },
  applyTemplateAndSaveProposal: async (templateId, proposalId) => {
    const res = await get().getTemplate(templateId);
    if (!res) return false;
    if (res.error) {
      emptyUrl();
      set(
        produce((state) => {
          state.currentProposal = { id: proposalId };
          state.currentDocument = getDocumentFromParams();
        }),
        false,
        `Template error : ${res.error.message}...`
      );
      return false;
    }
    res.content.configuration.fontFamily =
      res.content.configuration.fontFamily ?? defaultConfiguration.fontFamily;
    get().loadFont(res.content.configuration.fontFamily);
    set(
      produce((draft) => {
        mutateApplyTemplate(draft, res);
      }),
      false,
      'applyTemplateLayout'
    );
    set(
      produce((draft) => {
        mutateSetGridConfiguration(draft);
      }),
      false,
      'setGridConfiguration'
    );
    const {
      landscape,
      boxes,
      pages,
      masks,
      configuration,
      summaryConfiguration,
      gridConfiguration,
      variables,
      currentDocument,
      currentProposal,
      containers,
      columns,
      defaultVariablesApi,
      variableGroups,
      variableOrderArray,
      triggers,
    } = get();
    if (!currentDocument.id) return;

    const proposalData = await proposalsApi.postProposal({
      landscape,
      currentDocument,
      currentProposal,
      boxes,
      pages,
      masks,
      configuration,
      summaryConfiguration,
      gridConfiguration,
      containers,
      columns,
      variables,
      variableGroups,
      variableOrderArray,
      triggers,
    });
    proposalData.content = formatResContent(
      proposalData.content,
      defaultVariablesApi,
      false,
      landscape
    );
    const { id } = proposalData;
    set(
      produce((state) => {
        state.currentProposal = { id };
        Object.keys(proposalData.content).forEach((k) => {
          state[k] = proposalData.content[k];
        });
      }),
      false,
      'saveProposal'
    );
    return true;
  },

  createTemplateInStore: (templatable) => {
    set(
      produce((state) => {
        state.masks = defaultMasks;
        state.currentTemplate = null;
        state.currentProposal = null;
        state.configuration = defaultConfiguration;
        state.summaryConfiguration =
          defaultItemValues.summary.content.configuration;
        state.gridConfiguration = defaultItemValues;
        state.boxes = [];
        state.variables = {};
        state.tempaltable = templatable;
        state.containers = [];
        state.columns = [];
        state.variableGroups = [];
        state.copy = null;
        state.variableOrderArray = [];
        state.triggers = {};
        state.sectionsToActivate = [];
        state.hasOpportunityOption = false;
        state.hasCostingActivated = false;
        state.zoneNames = [];
        state.zoneIcon = 'fa-kit fa-solid-bars-progress-gear';
        state.itemsChangedByTriggers = [];
      }),
      false,
      'createTemplateInStore'
    );
  },
});

const mutateApplyTemplate = (draft, data) => {
  draft.copy = null;
  draft.landscape = data.landscape;
  draft.hasCostingActivated = data.has_costing_activated;
  draft.zoneIcon = data.zone_icon ?? draft.zoneIcon;
  Object.keys(data.content).forEach((k) => {
    draft[k] = data.content[k];
  });
  draft.currentTemplate = {
    name: data.name,
    id: data.id,
    templatable: data.templatable || 'opportunity',
    pattern: data.pattern,
    landscape: data.landscape,
    work: data.work,
    preview: data.preview,
    document_type_ids: data.document_type_ids,
  };
  draft.configuration = syncStructure(
    data.content.configuration,
    defaultConfiguration
  );
  draft.summaryConfiguration = syncStructure(
    data.content.summaryConfiguration,
    defaultItemValues.summary.content.configuration
  );
  draft.gridConfiguration = syncStructure(
    data.content.gridConfiguration,
    defaultItemValues
  );
  // draft.pages = syncPageStructure(data.content.pages);
  if (draft.hasOwnProperty('pages')) {
    delete draft.pages;
  }
  // mutateBoxesPageIndex(
  //   draft.boxes,
  //   draft.pages.map((p) => p.id)
  // );
  mutateBoxesOrderInfinite(draft);
  mutateTitlesIndexes(draft);
  mutateResizeSummary(draft);
};

const mutateSetGridConfiguration = (draft) => {
  const { margins, indentationTitles } = draft.configuration;
  draft.gridConfiguration[ItemTypes.SUMMARY] = {
    ...draft.gridConfiguration[ItemTypes.SUMMARY],
    width: calculateWidth(margins, indentationTitles, 0),
    left: calculateLeftPadding(margins, indentationTitles, 0),
  };
  draft.gridConfiguration[ItemTypes.TITLE] = {
    ...draft.gridConfiguration[ItemTypes.TITLE],
    width: calculateWidth(margins, indentationTitles, 0),
    left: calculateLeftPadding(margins, indentationTitles, 0),
  };
  draft.gridConfiguration[ItemTypes.TITLE_2] = {
    ...draft.gridConfiguration[ItemTypes.TITLE_2],
    width: calculateWidth(margins, indentationTitles, 1),
    left: calculateLeftPadding(margins, indentationTitles, 1),
  };
  draft.gridConfiguration[ItemTypes.TITLE_3] = {
    ...draft.gridConfiguration[ItemTypes.TITLE_3],
    width: calculateWidth(margins, indentationTitles, 2),
    left: calculateLeftPadding(margins, indentationTitles, 2),
  };
  draft.gridConfiguration[ItemTypes.TITLE_4] = {
    ...draft.gridConfiguration[ItemTypes.TITLE_4],
    width: calculateWidth(margins, indentationTitles, 3),
    left: calculateLeftPadding(margins, indentationTitles, 3),
  };
  draft.gridConfiguration[ItemTypes.TABLE] = {
    ...draft.gridConfiguration[ItemTypes.TABLE],
    width: calculateWidth(margins, indentationTitles, 0),
    left: calculateLeftPadding(margins, indentationTitles, 0),
  };
};
