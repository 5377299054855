import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Form } from 'antd'
import { ItemTypes, MENU_PARENT, pageBackground } from '../../constants/constants'
import { useDrag, useDrop } from 'react-dnd'
import { Editor } from 'draft-js'
import ReactDOM from 'react-dom';
import { getAncestorFromType, handleBeforeInput } from '../RichEditor/utils'
import ContextualMenu from '../ContextualMenu/ContextualMenu'
import { EditorProvider } from '../../contexts/EditorContext'

const LabelRow = memo(function LabelRow({
  item,
  index,
  onBlur,
  checkEmptyLabel,
  box,
  isLast,
  form,
  moveRow,
  onDrop,
  arrayKey,
  updateBox
}) {
  const [focused, setFocused] = useState(false);
  const { label } = item;
  const canDeleteLabel = box[arrayKey].length > 1;
  const labelForm = Form.useWatch([arrayKey, index, "label"])
  const editorRef = useRef()

  const setEditorState = useCallback((fn) => {
    if (!form) return;
    const newEditorState = fn(form.getFieldValue([arrayKey, index, "label"]));
    form.setFieldValue([arrayKey, index, "label"], newEditorState)
    onBlur()
  }, [arrayKey, form, index, onBlur])

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "label",
      item: { id: item.id },
      collect: (monitor) => ({
        isDragging: item.id === monitor.getItem()?.id,
      }),
      end: () => onDrop(),
    }),
    [item.id, onDrop]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "label",
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      hover({ id: draggedId }) {
        if (draggedId !== item.id) {
          moveRow({ draggedId, overId: item.id })
        }
      },
    }),
    [moveRow]
  );

  const deleteLabel = () => {
    if (canDeleteLabel)
      updateBox(box.id, (box) => {
        box[arrayKey] = box[arrayKey].filter((c) => c.id !== item.id);
        if (box.maxNumberOfAnwser > box[arrayKey].length) {
          box.maxNumberOfAnwser = box[arrayKey].length
          form.setFieldValue("maxNumberOfAnwser", box.maxNumberOfAnwser)
        }
        if (box.minNumberOfAnwser > box[arrayKey].length) {
          box.minNumberOfAnwser = box[arrayKey].length
          form.setFieldValue("minNumberOfAnwser", box.minNumberOfAnwser)
        }
      })
  }

  const opacity = isDragging ? 0.8 : 1;

  const getParent = () => {
    if (box.type === ItemTypes.CHECKBOXS_VARIABLE || box.type === ItemTypes.QUESTION) return MENU_PARENT.checkbox
    return MENU_PARENT.option
  }

  const menuAncestorIds = useMemo(() => [box.id, item.id], [box.id, item.id])

  return (
    <div ref={drop}>
      <div style={{
        display: 'flex',
        backgroundColor: 'white',
        alignItems: 'center',
        columnGap: 8,
        ...(!isLast && { borderBottom: '0.5px solid gray' }),
        padding: '2px 8px',
        opacity
      }}
        data-role={pageBackground}
        ref={preview}
      >
        <div ref={drag}>
          <FontAwesomeIcon
            icon={icon({ name: 'arrows-up-down-left-right', style: 'solid' })}
            style={{ cursor: 'grab' }}
          />
        </div>
        <EditorProvider setEditorState={setEditorState} editorState={label}>
          <Form.Item
            className={`variable-editor__input ${focused ? 'focused' : ''}`}
            name={[arrayKey, index, "label"]}
            initialValue={label}
            preserve={false}
            onBlur={() => {
              onBlur()
            }}
            style={{
              marginBottom: 0,
              flex: 1,
              overflow: 'hidden',
            }}
            rules={[{ required: true }]}
            help=""
            valuePropName='editorState'
          >
            <Editor
              stripPastedStyles={true}
              ref={editorRef}
              handleBeforeInput={(chars, editorState) => handleBeforeInput(
                {
                  chars,
                  editorState,
                  setEditorState: (newEditorState) => form.setFieldValue([arrayKey, index, "label"], newEditorState)
                }
              )}
              data-role={pageBackground} onFocus={() => setFocused(true)} onChange={checkEmptyLabel} onBlur={() => setFocused(false)} />
          </Form.Item>
        </EditorProvider>
        <FontAwesomeIcon
          icon={icon({ name: 'trash', style: 'light' })}
          style={{
            color: canDeleteLabel ? 'red' : 'gray',
            cursor: canDeleteLabel ? 'pointer' : 'not-allowed'
          }}
          onClick={deleteLabel}
        />
      </div>
      {!!labelForm && ReactDOM.createPortal(
        <ContextualMenu
          editorState={labelForm}
          setEditorState={setEditorState}
          menuAncestorType={getAncestorFromType(box.type, getParent())}
          menuAncestorIds={menuAncestorIds}
        />,
        document.getElementById('SelectionVariablePortal')
      )}
    </div>
  )
})

export default LabelRow
