import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TriggerableTypes, variablesIcons } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../store/store';
import { Select } from 'antd';
const { Option } = Select

const TriggerTypeSelect = ({ box, triggerType, record }) => {
  const { t } = useTranslation()
  const updateTrigger = useStore(({ updateTrigger }) => updateTrigger)
  const isOffer = useStore(({ currentTemplate }) => currentTemplate.templatable === 'offer')
  const hasCostingActivated = useStore(({ hasCostingActivated }) => hasCostingActivated)
  const zoneIcon = useStore(({ zoneIcon }) => zoneIcon)

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Select
        popupClassName="trigger-select-type"
        bordered={false}
        showSearch={false}
        optionLabelProp="label"
        value={triggerType}
        showArrow={!triggerType}
        onSelect={(type) => updateTrigger(record.id, box.id, { type })}
      >
        {Object.values(TriggerableTypes)
          .filter((type) => (type !== 'optional-zone' && type !== 'zone-name') || (!isOffer && hasCostingActivated))
          .map((type) =>
            <Option key={type} value={type} label={type === 'zone-name' ? <i className={zoneIcon} />
              : <FontAwesomeIcon icon={variablesIcons[type]} />}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8
              }}>
                {type === 'zone-name' ? <i className={zoneIcon} />
                  : <FontAwesomeIcon icon={variablesIcons[type]} />}
                {t(type)}
              </div>
            </Option>
          )
        }
      </Select>
    </div>
  )
}

export default TriggerTypeSelect
