import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TableWithDrag from '../../common/TableWithDrag'
import InputHideable from '../../common/InputHideable'
import { tableColor, tableHeaderSecondaryBgColor, variableHandlingType, variablesIcons } from '../../../constants/constants'
import { convertContentToRawText } from '../../RichEditor/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../store/store'
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons'
import FormCreateItemButton from './FormCreateItemButton'
import { sortAndFilterVariablesGroupedByContainer } from '../../../utils/variableOrderArray'
import { scrollToItemInVariableEditor } from '../FormViewer/FormViewer'
import { hasVariableBoxError } from '../../../utils/boxes'
import { faCircleExclamation, faTrashXmark } from '@fortawesome/pro-duotone-svg-icons'
import { Popconfirm, Tooltip } from 'antd'
import { Editor } from 'draft-js'
import { searchCaseDiatricsInsensitive } from '../../../utils/general'
import { useQueryDocumentSections } from '../../../hooks'
import { shouldHideRowInForm } from '../../../utils/variables'

const formatVariables = ({ variablesGroupedByContainer, variableOrderArray, documentType, sectionList }) => {
  return sortAndFilterVariablesGroupedByContainer(variablesGroupedByContainer, variableOrderArray).filter((vInfos) => {
    if (documentType !== 'template' &&
      shouldHideRowInForm({ vInfos, sectionList })) return false
    return true;
  }).map((vInfos) => {
    if (vInfos.type === variableHandlingType.FORM) return vInfos.variablesBoxes.map((b) => ({ ...b, canDrag: !!b.onlyForm, containerId: vInfos.containerId }))
    if (vInfos.variableGroupBox) return { ...vInfos.variableGroupBox, containerId: vInfos.containerId }
    return vInfos.variablesBoxes.map((b) => ({ ...b, containerId: vInfos.containerId }))
  }).flat().map(((v, index) => ({ ...v, key: `${v.id}-${index}` })))
}

const FormItemsArray = ({ variablesGroupedByContainer, setCreateItemType }) => {
  const documentType = useStore(({ currentDocument }) => currentDocument.type)
  const documentId = useStore(({ currentDocument, currentProposal }) =>
    currentDocument.type === 'template'
      ? currentDocument.id
      : currentProposal.id
  );
  const { data: sectionList } = useQueryDocumentSections({
    documentType,
    documentId,
  });
  const variableOrderArray = useStore(({ variableOrderArray }) => variableOrderArray)
  const boxVariableSelectedId = useStore(({ boxVariableSelectedId }) => boxVariableSelectedId)
  const handleBoxVariableSelectedId = useStore(({ handleBoxVariableSelectedId }) => handleBoxVariableSelectedId)
  const itemsChangedByTriggers = useStore(({ itemsChangedByTriggers }) => itemsChangedByTriggers)
  const deleteItemChangedByTriggers = useStore(({ deleteItemChangedByTriggers }) => deleteItemChangedByTriggers)
  const areSectionsActivating = useStore(({ areSectionsActivating }) => areSectionsActivating)
  const [searchValue, setSearchValue] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [dataSource, setDataSource] = useState(formatVariables({ variablesGroupedByContainer, variableOrderArray, documentType, sectionList }));
  const reorderVariables = useStore(({ reorderVariables }) => reorderVariables)
  const removeBox = useStore(({ removeBox }) => removeBox)
  const { t } = useTranslation()

  useEffect(() => {
    const newData = formatVariables({ variablesGroupedByContainer, variableOrderArray, documentType, sectionList })
    if (searchValue) {
      setDataSource(newData
        .filter((record) => searchCaseDiatricsInsensitive(searchValue, typeof record.name === "string" ? record.name : convertContentToRawText(record.name)))
        .map((record) => ({
          ...record,
          canDrag: false,
          dragIconTitle: t('unmovable-search-active')
        })))
    } else {
      setDataSource(newData)
    }
  }, [searchValue, variablesGroupedByContainer, t, variableOrderArray, sectionList, documentType])

  const onDrop = useCallback(() => {
    reorderVariables(dataSource)
  }, [dataSource, reorderVariables])

  const ref = useRef()

  const getPositions = useCallback(() => {
    if (!ref.current) return { top: null, left: null }
    const { left, top, width } = ref.current.getBoundingClientRect()
    return { left: left + width, top }
  }, [])

  const columns = useMemo(() => {
    const base = [
      {
        title: <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 16
        }}>
          {t('name')}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            flex: 1
          }}>
            <InputHideable
              setValue={setSearchValue}
              value={searchValue}
            />
            {documentType === 'template' && <FontAwesomeIcon
              ref={ref}
              onClick={() => setMenuIsOpen(true)}
              style={{
                height: 16,
                color: 'white',
                cursor: 'pointer'
              }} icon={faCirclePlus} />}
          </div>
        </div>,
        dataIndex: 'name',
        render: (name, record) => <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 6,
        }}>
          <FontAwesomeIcon icon={variablesIcons[record.type]} />
          {typeof name === "string" ? name : <Editor
            readOnly
            editorState={name} />}
          {documentType !== 'template' && hasVariableBoxError(record).error && <Tooltip title={hasVariableBoxError(record).message}>
            <FontAwesomeIcon
              icon={faCircleExclamation} color='red' fontSize={14} style={{
                marginLeft: 'auto'
              }} />
          </Tooltip>
          }
        </div >
        ,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
        onCell: (record) => ({
          onClick: () => {
            handleBoxVariableSelectedId(record.id)
            if (documentType === 'template')
              setCreateItemType()
            scrollToItemInVariableEditor(record.id, sortAndFilterVariablesGroupedByContainer(variablesGroupedByContainer, variableOrderArray))
          },
          style: {
            cursor: 'pointer'
          }
        })

      },
    ]
    if (documentType === 'template') return [
      ...base,
      {
        width: 25,
        dataIndex: 'delete',
        render: (_, record) =>
          <Popconfirm
            title="Supprimer la question ?"
            cancelText='Annuler'
            placement='bottom'
            onConfirm={() => removeBox(record.id)}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: "100%",
              cursor: 'pointer'
            }}>
              {record.onlyForm && <FontAwesomeIcon color='red' icon={faTrashXmark} />}
            </div >
          </Popconfirm>
        ,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
      },
    ]
    return base
  }, [documentType, handleBoxVariableSelectedId, removeBox, searchValue, setCreateItemType, t, variableOrderArray, variablesGroupedByContainer]);

  const rowSelectedId = useMemo(() => {
    if (!boxVariableSelectedId) return
    const v = variablesGroupedByContainer.find((v) => v.containerId === boxVariableSelectedId || v.variablesBoxes.some(({ id }) => id === boxVariableSelectedId))
    if (v.type === variableHandlingType.FORM) return boxVariableSelectedId
    return v.containerId
  }, [boxVariableSelectedId, variablesGroupedByContainer])

  const rowClassNameforItemsChanged = useCallback((record) => {
    let base = "form-item-array-row";
    if (itemsChangedByTriggers.some(({ key }) => key === record.id) && !areSectionsActivating) {
      base += ` form-item-array-row-changed record-id-${record.id}`;
    }
    return base;
  }, [itemsChangedByTriggers, areSectionsActivating])

  return (
    <div
      onAnimationEnd={(e) => {
        const element = e.target;
        const className = element.className;
        const recordIdMatch = className.match(/record-id-([^\s]+)/);
        if (recordIdMatch) {
          const recordId = recordIdMatch[1];
          deleteItemChangedByTriggers(recordId)
        }
      }}
    >
      <FormCreateItemButton menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} getPositions={getPositions} setCreateItemType={setCreateItemType} />
      <TableWithDrag
        columns={columns}
        dataSource={dataSource}
        setDataSource={setDataSource}
        backgroundColor={tableHeaderSecondaryBgColor}
        color={tableColor}
        onDrop={onDrop}
        scroll={{ y: "calc(100vh - 54px - 60px - 16px - 37.5px" }}
        withDragger={documentType === 'template'}
        rowSelectedId={rowSelectedId}
        rowKey="key"
        rowClassName={rowClassNameforItemsChanged}
      />
    </div>
  )
}

export default FormItemsArray
