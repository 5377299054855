import React from 'react'
import { Select } from 'antd'
import { useStore } from '../../../store/store'
import { useQueryDocumentSections } from '../../../hooks'
import ModalImage from 'react-modal-image';
const { Option } = Select;

const selector =
  ({
    currentDocument,
    currentProposal,
  }) => {
    return {
      documentType: currentDocument.type,
      documentId: currentDocument.type === 'template' ? currentDocument.id : currentProposal.id,
    }
  };

const FormSectionBox = ({ value, onSelect, ...props }) => {
  const {
    documentType,
    documentId,
  } = useStore(selector);
  const { data: sectionList } = useQueryDocumentSections({ documentType, documentId })

  return (
    <Select
      style={{
        flex: 1,
        width: '100%',
      }}
      value={value ?? undefined}
      onSelect={onSelect}
      dropdownMatchSelectWidth={false}
      optionLabelProp="label"
      showSearch
      optionFilterProp="label"
      dropdownStyle={{ zIndex: 9999 }}
      getPopupContainer={triggerNode => triggerNode.parentElement}
      allowClear
      onClear={onSelect}
      {...props}
    >
      {(sectionList || []).map(({ name, id, preview }) => {
        return (
          <Option key={id} value={id} label={name}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: 500
            }}>
              <div style={{
                width: 420,
                whiteSpace: 'break-spaces',
                wordWrap: 'break-word',
              }}>
                {name}
              </div>
              <div style={{ width: 80 }}>
                <ModalImage
                  small={preview}
                  large={preview}
                  alt='Aperçu'
                />
              </div>
            </div>
          </Option>
        )
      })}
    </Select>
  )
}

export default FormSectionBox
