import { variableHandlingType } from '../constants/constants';

export const mutateInsertVariableBox = (draft, box) => {
  if (box.from_linked_section) return;
  if (!box.columnId) {
    if (box.boxSectionId) return;
    draft.variableOrderArray.unshift({
      id: box.id,
      containerId: box.id,
    });
    return;
  }
  const boxContainerIdx = draft.containers.findIndex((c) =>
    c.columnsIds.includes(box.columnId)
  );
  const afterContainer = draft.containers.find(
    (c, idx) =>
      boxContainerIdx <= idx &&
      draft.variableOrderArray.some(({ containerId }) => containerId === c.id)
  );
  if (afterContainer) {
    const afterOrderIdx = draft.variableOrderArray.findIndex(
      ({ containerId }) => containerId === afterContainer.id
    );
    draft.variableOrderArray = [
      ...draft.variableOrderArray.slice(0, afterOrderIdx),
      { id: box.id, containerId: draft.containers[boxContainerIdx].id },
      ...draft.variableOrderArray.slice(afterOrderIdx),
    ];
  } else {
    draft.variableOrderArray.push({
      id: box.id,
      containerId: draft.containers[boxContainerIdx].id,
    });
  }
};

export const mutateTakeOutItem = (draft, vId, parentId) => {
  const idx = draft.variableOrderArray.findIndex(
    ({ id, parentId: vParentId }) =>
      id === vId && (!parentId || parentId === vParentId)
  );
  if (idx !== -1) draft.variableOrderArray.splice(idx, 1);
};

export const mutateRemoveUnusedVariableGroup = (draft) => {
  draft.variableGroups.forEach((vG) => {
    const vGIsUsed = vG.variableIds.some((id) => !draft.variables[id]?.notUsed);
    if (!vGIsUsed) {
      mutateTakeOutItem(draft, vG.id);
    }
  });
};

const getIdAndContainerIdFromSection = ({ boxesToAdd, id, containerId }) => {
  if (boxesToAdd.some(({ boxSectionId }) => boxSectionId === id)) {
    const b = boxesToAdd.find(({ boxSectionId }) => boxSectionId === id);
    return { id: b.id, containerId: b.id };
  }
  return { id, containerId };
};

export const mutateSortVariableFromSection = ({
  draft,
  sectionContent,
  boxesToAdd,
  containerIdx,
  parentContainerId,
  parentId,
}) => {
  const boxesToAddSectionIds = boxesToAdd
    .filter((b) => !b.onlyForm)
    .map(({ boxSectionId }) => boxSectionId);
  const afterContainer = draft.containers.find(
    (c, idx) =>
      c.id !== parentContainerId &&
      containerIdx <= idx &&
      draft.variableOrderArray.some(({ containerId }) => containerId === c.id)
  );
  if (afterContainer) {
    const afterOrderIdx = draft.variableOrderArray.findIndex(
      ({ containerId }) => containerId === afterContainer.id
    );
    let firstIdxVariable = draft.variableOrderArray.findIndex(
      ({ id }) => id === boxesToAdd.some(({ id: bId }) => id === bId)
    );
    firstIdxVariable = firstIdxVariable === -1 ? 0 : firstIdxVariable;
    const sectionDiff = afterOrderIdx - firstIdxVariable;
    sectionContent.variableOrderArray
      .filter(({ id }) => {
        if (
          boxesToAddSectionIds.includes(id) ||
          (draft.currentDocument.type !== 'template' &&
            !draft.variableOrderArray.some(
              ({ id: vId, parentId: vParentId }) =>
                id === vId && parentId === vParentId
            ))
        )
          return true;
        return !draft.variableOrderArray.some(({ id: vId }) => id === vId);
      })
      .forEach(({ id: vId, containerId: vContainerId }, idx) => {
        if (!boxesToAddSectionIds.includes(vId)) {
          const { id, containerId } = getIdAndContainerIdFromSection({
            boxesToAdd,
            id: vId,
            containerId: vContainerId,
          });
          draft.variableOrderArray = [
            ...draft.variableOrderArray.slice(0, sectionDiff + idx),
            { id, containerId, parentId },
            ...draft.variableOrderArray.slice(sectionDiff + idx),
          ];
        }
      });
  } else {
    sectionContent.variableOrderArray.forEach(({ id, containerId }) => {
      if (
        !boxesToAddSectionIds.includes(id) &&
        (!draft.variableOrderArray.some(({ id: vId }) => id === vId) ||
          (draft.currentDocument.type !== 'template' &&
            !draft.variableOrderArray.some(
              ({ id: vId, parentId: vParentId }) =>
                id === vId && parentId === vParentId
            )))
      ) {
        draft.variableOrderArray.push({ id, containerId, parentId });
      }
    });
  }
};

export const mutateInsertVariableInOrderArray = (draft, variableKey) => {
  if (draft.variableGroups.some((vG) => vG.variableIds.includes(variableKey))) {
    const vG = draft.variableGroups.find((vG) =>
      vG.variableIds.includes(variableKey)
    );
    if (!draft.variableOrderArray.some(({ id }) => id === vG.id))
      draft.variableOrderArray.unshift({ id: vG.id, containerId: vG.id });
  } else {
    if (!draft.variableOrderArray.some(({ id }) => id === variableKey))
      draft.variableOrderArray.unshift({
        id: variableKey,
        containerId: variableKey,
      });
  }
};

export const sortAndFilterVariablesGroupedByContainer = (
  variablesGroupedByContainer,
  variableOrderArray
) => {
  const variablesToUse = variablesGroupedByContainer.filter((vInfos) => {
    if (vInfos.type === variableHandlingType.FORM) return true;
    if (vInfos.variableGroupBox)
      return (
        vInfos.variablesBoxes.length > 0 &&
        vInfos.variablesBoxes.some((v) => !v.notUsed)
      );
    return !vInfos.variablesBoxes[0].notUsed;
  });
  const array = variableOrderArray
    .map(({ id }) => {
      return variablesToUse.find((vInfos) => {
        if (vInfos.variableGroupBox) return vInfos.variableGroupBox.id === id;
        return vInfos.variablesBoxes[0].id === id;
      });
    })
    .filter((obj) => obj !== undefined);

  return variablesToUse
    .filter((vInfos) => {
      if (vInfos.type === variableHandlingType.FORM) return false;
      if (vInfos.variableGroupBox)
        return !array.some(
          ({ containerId }) => containerId === vInfos.variableGroupBox.id
        );
      return !array.some(
        ({ containerId }) => containerId === vInfos.variablesBoxes[0].id
      );
    })
    .concat(array);
};
