import {
  useCallback,
  useEffect, useMemo, useRef, useState
} from 'react';
import ReactDOM from 'react-dom';

// RichEditor
import { contentChanged, handleBeforeInput, decorator } from '../RichEditor/utils';
import '../RichEditor/index.css'

import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  Editor, EditorState, getDefaultKeyBinding, Modifier, RichUtils, SelectionState
} from 'draft-js';
import { replaceVariablesInEditorState, findWithRegex } from '../../utils/dataSync';
import { useStore } from '../../store/store';
import {
  MENU_ANCESTORS
} from '../../constants/constants';

// common components
import '../common/Utils.css';


import { getBackgroundBorderStyle } from '../../utils/styles';
import { isAVariableBox } from '../../utils/boxes';
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';

import { debounce } from 'lodash';
import EditorWrapper from './EditorWrapper';
import { useStyleMap } from '../../hooks';
import ContextualMenu from '../ContextualMenu/ContextualMenu';
import { EditorProvider } from '../../contexts/EditorContext';
import FloatyTextStyling from '../Table/FloatyTextStyling';
import TextStylingComponent from '../TextStylingComponent/TextStylingComponent';

const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'ordered-list-item':
      return 'RichEditor-ordered-list-item-no-marker';
    default:
      return null;
  }
};

export const setDefaultInlineStyleIfNeeded = (
  currentEditorState,
  removedProperty,
  defaultProperty,
  prefix = ''
) => {
  let nextContentState = currentEditorState.getCurrentContent();
  const blocks = [];
  nextContentState.getBlockMap().forEach((block) => {
    const characterList = block.getCharacterList().map((character) => {
      if (character.hasStyle(`${prefix}${removedProperty}`)) {
        return CharacterMetadata.removeStyle(
          character,
          `${prefix}${removedProperty}`
        );
      } else {
        return character;
      }
    });
    blocks.push(
      new ContentBlock({
        key: block.getKey(),
        text: block.getText(),
        type: block.getType(),
        depth: block.getDepth(),
        data: block.getData(),
        characterList,
      })
    );
  });

  return EditorState.push(
    currentEditorState,
    ContentState.createFromBlockArray(blocks),
    'change-inline-style'
  );
};

// Store selector
const selector = (box) => ({ selectedBoxId, removeBox, updateBox, configuration, variables, setBoxMovable, currentDocument, setEventManagerEnabled, fromPdf }) => {
  const id = box.id

  return {
    removeBox: removeBox,
    updateBox: box.updateBox ?? updateBox,
    colors: configuration.colors,
    variables: variables,
    configuration: configuration,
    isSelected: box.isSelected ?? selectedBoxId === box.id,
    setBoxMovable: setBoxMovable,
    isTemplate: currentDocument.type === 'template',
    isOffer: currentDocument.type === 'offer',
    setEventManagerEnabled: setEventManagerEnabled,
    fromPdf: fromPdf,
    textStyle: configuration.textStyles.find((ts) => ts.id === box?.textStyleId) || configuration.textStyles[0]

  };
};

const TextVariable = ({ box, drawMode }) => {
  const editorRef = useRef(null);
  const boundingBoxRef = useRef(null);
  const { updateLeftShownStatus } = useOverlay();
  const {
    updateBox,
    colors,
    variables,
    isSelected,
    isTemplate,
    setBoxMovable,
    configuration,
    setEventManagerEnabled,
    fromPdf,
    textStyle
  } = useStore(selector(box));

  const styleMap = useStyleMap()

  const [editorState, setEditorState] = useState(EditorState.createWithContent(
    box.content.editorState.getCurrentContent(),
    decorator
  ));
  const [defaultSelection, setDefaultSelection] = useState(null);

  useEffect(() => {
    if (!isSelected) {
      setEditorState(EditorState.createWithContent(
        box.content.editorState.getCurrentContent(),
        decorator
      ))
    }
  }, [box.content, isSelected]);


  const saveBox = useCallback(debounce((editorState) => {
    if (boundingBoxRef.current) {
      updateBox(box.id, (box) => {
        if (!box.isBoxSimulated)
          box.height = boundingBoxRef?.current.firstChild.clientHeight;
        box.content.editorState = EditorState.createWithContent(
          editorState.getCurrentContent(),
          decorator
        );
      });
    }
  }, 100), [box?.id, updateBox]);

  useEffect(() => {
    if (isSelected) {
      saveBox(editorState)
    }
  }, [isSelected, saveBox, editorState]);

  useEffect(() => {
    if (!isSelected) {
      setEditorState(EditorState.createWithContent(
        box.content.editorState.getCurrentContent(),
        decorator
      ))
    }
  }, [box.content.editorState, isSelected]);

  const setEditorContentWithReplacement = (editorObject = editorState) => {
    const regex = new RegExp(/#{.*?}/, 'm');
    const contentIsNew = contentChanged(editorState, editorObject);
    let modifiedEditorState = editorObject;
    if (contentIsNew && variables && !isTemplate && findWithRegex(
      regex,
      modifiedEditorState.getCurrentContent().getPlainText()
    ) != null) {
      // modifiedEditorState = replacePlaceholder(editorObject, variables, documentType)
      modifiedEditorState = replaceVariablesInEditorState(modifiedEditorState, variables);
    }
    setEditorState(modifiedEditorState);
  }

  const write = (editorState) => {
    setEditorContentWithReplacement(editorState);
  };

  const handleKeyCommand = useCallback(
    (command) => {
      if (command) {
        setEditorState(
          RichUtils.toggleInlineStyle(editorState, command.toUpperCase())
        );
      }
    },
    [editorState]
  );

  const handleTab = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    const currentContent = editorState.getCurrentContent();
    const blockMap = currentContent.getBlockMap();
    const key = blockMap.last().getKey();
    const newSelection = new SelectionState({
      anchorKey: key,
      focusKey: key,
    });
    let textWithInsert = '';
    let editorWithInsert = '';
    let newEditorState = '';
    if (
      blockType === 'unordered-list-item' ||
      blockType === 'ordered-list-item'
    ) {
      const newState = RichUtils.onTab(e, editorState, 4);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      } else {
        return 'not-handled';
      }
    } else {
      textWithInsert = Modifier.insertText(
        currentContent,
        newSelection,
        '    ',
        null
      );
      editorWithInsert = EditorState.push(
        editorState,
        textWithInsert,
        'insert-characters'
      );
      newEditorState = EditorState.moveFocusToEnd(
        editorWithInsert,
        textWithInsert.getSelectionAfter()
      );
      setEditorState(newEditorState);
    }
  };

  const myKeyBindingFn = (e) => {
    if (e.keyCode === 9 /* `S` key */) {
      handleTab(e);
    }
    return getDefaultKeyBinding(e);
  };

  // If in default container, focus on one click
  useEffect(() => {
    if (!drawMode && isSelected) {
      editorRef.current.focus();
    }
  }, [drawMode, isSelected])

  const applyStyleTextVariable = useCallback((style) => {
    if (box[style] === undefined) {
      return textStyle[style]
    } else {
      return box[style]
    }
  }, [box, textStyle])

  useEffect(() => {
    var root = document.querySelector(':root');
    var element0 = configuration.unorderedListBulletValue0;
    var element1 = configuration.unorderedListBulletValue1;
    var element2 = configuration.unorderedListBulletValue2;
    var element3 = configuration.unorderedListBulletValue3;
    var element4 = configuration.unorderedListBulletValue4;
    var customBulletPadding = configuration.customBulletPadding;

    root.style.setProperty('--unorderedSymbol0', element0);
    root.style.setProperty('--unorderedSymbol1', element1);
    root.style.setProperty('--unorderedSymbol2', element2);
    root.style.setProperty('--unorderedSymbol3', element3);
    root.style.setProperty('--unorderedSymbol4', element4);
    root.style.setProperty('--customBulletPaddingCSS', customBulletPadding);
  }, [
    configuration.unorderedListBulletValue,
    configuration.unorderedListBulletValue0,
    configuration.unorderedListBulletValue1,
    configuration.unorderedListBulletValue2,
    configuration.unorderedListBulletValue3,
    configuration.unorderedListBulletValue4,
    configuration.customBulletPadding,
  ]);

  const placeholder = useMemo(() => {
    const bulletStatus = RichUtils.getCurrentBlockType(editorState)
    return fromPdf ||
      bulletStatus === "unordered-list-item" ||
      bulletStatus === "ordered-list-item" ?
      "" : 'Votre texte ici'
  }, [editorState, fromPdf])

  const menuAncestorIds = useMemo(() => [box.id], [box.id])

  return (
    <EditorProvider setEditorState={setEditorState} editorState={editorState} readOnly={!isSelected}>
      <div
        ref={boundingBoxRef}
        id={box.id}
        style={{
          ...getBackgroundBorderStyle(applyStyleTextVariable("backgroundColor"), applyStyleTextVariable("border")),
          width: box.width,
          fontFamily: textStyle.fontFamily,
          fontSize: textStyle.fontSize,
          color: textStyle.color.style,
          fontWeight: textStyle.fontWeight,
          fontStyle: textStyle.fontStyle,
          textDecoration: textStyle.textDecoration,
        }}
        onClick={() => {
          if (isSelected && !editorState.getSelection().getHasFocus()) {
            editorRef.current.focus();
          }
        }}
        onDoubleClick={(event) => {
          if (isAVariableBox(box?.type) && !box.isBoxSimulated) {
            updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
          }
          event.stopPropagation()
        }}
      >
        <div style={{
          padding: `${textStyle.paddingTopBottom}px ${textStyle.paddinngLeftRight}px`
        }}>
          <EditorWrapper boxId={box.id} editorState={editorState}>
            <Editor
              stripPastedStyles={true}
              handleBeforeInput={(chars, editorState) => handleBeforeInput(
                {
                  chars,
                  editorState,
                  setEditorState
                }
              )}
              placeholder={placeholder}
              blockStyleFn={getBlockStyle}
              className='RichEditor-editor'
              style={{
                overflow: 'visible',
              }}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={handleKeyCommand}
              onBlur={() => saveBox(editorState)}
              onFocus={(event) => {
                setBoxMovable(false);
                setEventManagerEnabled(false);
              }}
              onChange={write}
              keyBindingFn={myKeyBindingFn}
              spellCheck={true}
              ref={editorRef}
              textAlignment={box.content.alignment || textStyle.positions.horizontal}
              readOnly={!isSelected}
            />
          </EditorWrapper>
        </div>
      </div>
      {isSelected &&
        ReactDOM.createPortal(
          <ContextualMenu
            editorState={editorState}
            setEditorState={setEditorState}
            menuAncestorType={MENU_ANCESTORS.BOX.content}
            menuAncestorIds={menuAncestorIds}
            defaultSelection={defaultSelection}
            setDefaultSelection={setDefaultSelection}
          />,
          document.getElementById('SelectionVariablePortal')
        )}
      {isSelected && !box.isBoxSimulated &&
        ReactDOM.createPortal(
          <TextStylingComponent
            box={box}
            updateBox={updateBox}
            editorState={editorState}
            setEditorState={setEditorState}
            variables={variables}
            colors={colors}
            textStyle={textStyle}
            boxId={box.id}
            hasAlignement
            updateBoxOnStylingChange={() => null}
            setDefaultSelection={setDefaultSelection}
          />,
          document.getElementById('ComponentPortal')
        )}
      {isSelected && box.isBoxSimulated &&
        ReactDOM.createPortal(
          <FloatyTextStyling
            editorState={editorState}
            setEditorState={setEditorState}
            defaultFontSize={textStyle?.fontSize}
            defaultFontFamily={textStyle?.fontFamily}
            defaultColor={textStyle?.color?.label}
            boxId={box.id}
            updateBox={updateBox}
            hasAlignement
            textStyle={box.withMenu ? textStyle : undefined}
          />,
          document.getElementById('SelectionVariablePortal')
        )
      }
    </EditorProvider>
  );
};

export default TextVariable;
