import { ItemTypes, MASKS_TYPE, uuid } from '../constants/constants';
import { gridUnit } from '../constants/gridConfig';
import {
  isATitleBox,
  isAVariableBox,
  mutateAddBoxes,
  mutateDefaultBoxValue,
  mutateRemoveBox,
  reinitializeBoxWidthAndHeight,
} from './boxes';
import { computeColumnWidth, mutateAddColumns } from './columns';
import { mutateRemoveContainer, mutateRemoveContainers } from './containers';
import { indentTitles } from './titles';
import {
  mutateExecuteParentTriggers,
  mutateInjectTriggersSection,
  mutateSearchAndLaunchTrigger,
} from './triggers';
import {
  mutateSortVariableFromSection,
  mutateTakeOutItem,
} from './variableOrderArray';
import { mutateInjectVariablesInBox } from './variables';

export const mutateFormatSectionBox = ({ box, type, draft }) => {
  if (type === ItemTypes.CHECKBOXS_VARIABLE || type === ItemTypes.QUESTION) {
    box = {
      ...box,
      checkboxStyleId: 0,
    };
  }
  if (type === ItemTypes.SELECTOR_VARIABLE) {
    box = {
      ...box,
      selectorStyleId: 0,
    };
  }
  if (type === ItemTypes.TEXT_VARIABLE) {
    box = {
      ...box,
      textStyleId: 0,
    };
  }
  box = mutateInjectVariablesInBox({
    variables: draft.variables,
    box,
  });
};

export const mutateInjectSection = ({
  draft,
  sectionContent,
  boxId,
  sectionId,
  linked_section = false,
  areItemsChangedByTriggers = false,
}) => {
  const box = draft.boxes.find((b) => b.id === boxId);
  const containerIdx = draft.containers.findIndex((c) =>
    c.columnsIds.includes(box.columnId)
  );
  const parentContainerId = draft.containers[containerIdx].id;
  const columnsIdDict = {};
  const columnsToAdd = sectionContent.columns.map((c) => {
    const newId = uuid();
    columnsIdDict[c.id] = newId;
    return {
      ...c,
      id: newId,
      from_linked_section: linked_section,
    };
  });
  const dateTimestamp = Date.now();
  if (linked_section) {
    linkSectionContainer({
      draft,
      sectionId,
      box,
    });
  }
  const containersToAdd = sectionContent.containers.map((c) => {
    let containerEditability = true;
    if (typeof c?.editable !== 'boolean' || c?.editable)
      containerEditability = true;
    else containerEditability = false;
    let baseContainer = {
      ...c,
      id: uuid(),
      sectionId: sectionId,
      from_linked_section: linked_section,
      dateTimestamp: dateTimestamp,
      editable: draft.configuration.editableTemplate && containerEditability,
      columnsIds: c.columnsIds.map((cId) => columnsIdDict[cId]),
    };
    if (box.type === ItemTypes.SECTION_VARIABLE) {
      return {
        ...baseContainer,
        sectionVariableBoxId: boxId,
      };
    }
    return baseContainer;
  });
  const boxesToAdd = mutateDefaultBoxValue(
    sectionContent.boxes.map((b) => {
      let baseBox = {
        ...b,
        id: uuid(),
        from_linked_section: linked_section,
        columnId: columnsIdDict[b.columnId],
        boxSectionId: b.id,
        sectionVariableBoxId: boxId,
      };
      const column = columnsToAdd.find((c) => c.id === baseBox.columnId);
      if (column && !column.drawMode) {
        const container = containersToAdd.find((c) =>
          c.columnsIds.includes(column.id)
        );
        baseBox = reinitializeBoxWidthAndHeight({
          header: draft.masks.find(({ id }) => id === MASKS_TYPE.HEADER.id),
          footer: draft.masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id),
          box: baseBox,
          onResize: true,
          columnWidth: computeColumnWidth({
            landscape: draft.landscape,
            marginLeft: draft.configuration.margins.left * gridUnit,
            marginRight: draft.configuration.margins.right * gridUnit,
            containerColumnsLength: container.columnsIds.length,
            size: column.size,
          }),
        });
      }
      mutateFormatSectionBox({ box, type: box.type, draft });
      if (isATitleBox(baseBox.type)) {
        baseBox = indentTitles({
          box: baseBox,
          columns: columnsToAdd,
          margins: draft.configuration.margins,
          indentationTitles: draft.configuration.indentationTitles,
        });
      }
      return baseBox;
    })
  );

  draft.containers.splice(containerIdx + 1, 0, containersToAdd);
  draft.containers = draft.containers.flat();
  mutateAddColumns(draft, columnsToAdd);
  mutateAddBoxes({ draft, newBoxes: boxesToAdd });
  if (!linked_section) {
    mutateInjectTriggersSection({ sectionContent, draft, boxesToAdd });
    mutateSortVariableFromSection({
      draft,
      sectionContent,
      boxesToAdd,
      containerIdx: containerIdx,
      parentContainerId,
      parentId: boxId,
    });
  }
  if (
    box.type !== ItemTypes.SECTION_VARIABLE &&
    box.type !== ItemTypes.SECTION_LINK
  )
    mutateRemoveContainer(draft, draft.containers[containerIdx].id);
  if (!linked_section) {
    Object.entries(sectionContent.variables).forEach(([key, value]) => {
      if (!value.notUsed && draft.variables[key].notUsed) {
        delete draft.variables[key].notUsed;
      }
      if (
        !draft.variables[key].notUsed &&
        draft.currentDocument.type !== 'template'
      ) {
        if (
          areItemsChangedByTriggers &&
          !draft.itemsChangedByTriggers.some(({ key: iKey }) => key === iKey)
        ) {
          draft.itemsChangedByTriggers.push({ key });
        }
        mutateExecuteParentTriggers({
          draft,
          key: key,
        });
        mutateSearchAndLaunchTrigger({
          triggerId: key,
          draft,
        });
      }
    });
    boxesToAdd.forEach((b) => {
      if (isAVariableBox(b.type) && draft.currentDocument.type !== 'template') {
        if (
          areItemsChangedByTriggers &&
          !draft.itemsChangedByTriggers.some(({ key: iKey }) => b.id === iKey)
        ) {
          draft.itemsChangedByTriggers.push({ key: b.id });
        }
        mutateExecuteParentTriggers({
          draft,
          key: b.id,
        });
        mutateSearchAndLaunchTrigger({
          triggerId: b.id,
          draft,
        });
      }
    });
  }
};

const linkSectionContainer = ({ draft, sectionId, box }) => {
  if (box.type === ItemTypes.SECTION_LINK) return;
  const sectionTitle = draft.sections.find((s) => s.id === sectionId).name;
  if (box) {
    box.sectionId = sectionId;
    box.type = ItemTypes.SECTION_LINK;
    box.sectionTitle = sectionTitle;
  }
};

export const mutateDeleteSection = ({ draft, boxId, optionId }) => {
  const containersToDelete = draft.containers.filter(
    (c) => c.sectionVariableBoxId === boxId
  );
  const sectionsBoxesToDelete = draft.boxes.filter(
    (b) =>
      containersToDelete.some((c) => c.columnsIds.includes(b.columnId)) &&
      b.type === ItemTypes.SECTION_VARIABLE
  );
  sectionsBoxesToDelete.forEach((sB) => {
    sB.answer = optionId;
    mutateDeleteSection({ draft, boxId: sB.id, optionId });
  });
  mutateRemoveContainers(
    draft,
    containersToDelete.map((c) => c.id)
  );
  draft.boxes
    .filter((b) => b.sectionVariableBoxId === boxId)
    .forEach((box) => mutateRemoveBox(draft, box.id));
  draft.variableOrderArray
    .filter(({ parentId }) => parentId === boxId)
    .forEach(({ id, parentId }) => {
      if (
        draft.variableOrderArray.some(
          ({ id: vId, parentId: vParentId }) =>
            id === vId && parentId !== vParentId
        )
      )
        mutateTakeOutItem(draft, id, parentId);
    });
};

export const getSelectedSection = (sectionId, sectionList) => {
  return (sectionList || []).find((s) => s.id === sectionId);
};

export const shouldHideSectionVariable = ({
  type,
  sectionId,
  sectionList,
  isActivatedByTrigger,
}) =>
  type === ItemTypes.SECTION_VARIABLE &&
  (!getSelectedSection(sectionId, sectionList) || isActivatedByTrigger);
