import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { contentPadding, pageHeight, pageWidth } from '../../../constants/gridConfig'
import { useStore } from '../../../store/store'
import { pageBackground, variableHandlingType } from '../../../constants/constants'
import { Virtuoso } from 'react-virtuoso'
import FormItem from './FormItem'
import { sortAndFilterVariablesGroupedByContainer } from '../../../utils/variableOrderArray'
import { shouldHideRowInForm } from '../../../utils/variables'
import { useQueryDocumentSections } from '../../../hooks'

const selector = ({
  configuration,
  currentDocument
}) => {
  return {
    fontSize: configuration.fontSize,
    fontFamily: configuration.fontFamily,
    documentType: currentDocument.type
  }
}

const List = React.forwardRef((props, ref) => {
  const {
    landscape
  } = useStore(selector);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: 'center',
      }}
      data-role={pageBackground}>
      <div
        {...props}
        ref={ref}
        style={{
          backgroundColor: 'rgb(245, 245, 245)',
          width: landscape ? pageHeight : pageWidth,
          paddingTop: 16,
          marginBottom: 16,
          ...props.style
        }}
        data-role={pageBackground}
      />
    </div>
  )
})

const listRef = React.createRef();

export const scrollToItemInVariableEditor = (boxVariableSelectedId, formItems) => {
  const containerIdx = formItems.filter((vInfos) => {
    if (vInfos.type === variableHandlingType.FORM) return true
    if (vInfos.variableGroupBox)
      return vInfos.variablesBoxes.some((v) => !v.notUsed)
    return !vInfos.variablesBoxes[0].notUsed
  }).findIndex((v) => {
    if (v.variableGroupBox && v.variableGroupBox.id === boxVariableSelectedId) return true
    return v.variablesBoxes.some((b) => b.id === boxVariableSelectedId)
  })
  listRef.current?.scrollToIndex(containerIdx);
};

const FormViewer = ({ variablesGroupedByContainer }) => {
  const { fontSize, fontFamily } = useStore(selector)
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')
  const {
    landscape
  } = useStore(selector);
  const documentType = useStore(({ currentDocument }) => currentDocument.type)
  const documentId = useStore(({ currentDocument, currentProposal }) =>
    currentDocument.type === 'template'
      ? currentDocument.id
      : currentProposal.id
  );

  const { data: sectionList } = useQueryDocumentSections({
    documentType,
    documentId,
  });

  const boxVariableSelectedId = useStore(({ boxVariableSelectedId }) => boxVariableSelectedId)
  const variableOrderArray = useStore(({ variableOrderArray }) => variableOrderArray)
  const [zoom, setZoom] = useState(100)

  const formItems = useMemo(() => {
    return sortAndFilterVariablesGroupedByContainer(variablesGroupedByContainer, variableOrderArray).filter((vInfos) => {
      if (shouldHideRowInForm({ vInfos, sectionList })) return false
      return true;
    }).map((vInfos) => {
      return {
        ...vInfos,
        variablesBoxes: vInfos.variablesBoxes.filter((v) => !v.hideField)
      }
    })
  }, [variablesGroupedByContainer, variableOrderArray, sectionList])

  useEffect(() => {
    scrollToItemInVariableEditor(boxVariableSelectedId, formItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderVariable = useCallback((index) => {
    const formItem = formItems[index]
    const hasVariableSelected = boxVariableSelectedId && ((formItem.variableGroupBox?.id === boxVariableSelectedId) ||
      formItem.variablesBoxes.some((b) => b.id === boxVariableSelectedId))

    return <FormItem
      hasVariableSelected={hasVariableSelected}
      formItem={formItem}
      zoom={zoom}
      formItems={formItems}
    />
  }, [boxVariableSelectedId, formItems, zoom])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (isTemplate) {
        setZoom(100 * ((window.innerWidth - 32) / 100 * (30 - (8.5 * 100 / (window.innerWidth - 32)))) / ((landscape ? pageHeight : pageWidth) + 64));
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      ref.current && observer.unobserve(ref.current);
    };

  }, [isTemplate, landscape])

  const ref = useRef()

  return (
    <div style={{
      height: '100%',
      width: `calc(${landscape ? pageHeight : pageWidth}px + 64px)`,
      paddingTop: contentPadding,
      display: 'flex',
      justifyContent: 'center',
      fontSize: `${fontSize}px`,
      fontFamily: fontFamily,
      zoom: `${zoom}%`,
      backgroundColor: "#f0f2f5"
    }}
      ref={ref}
      data-role={pageBackground}
    >
      <Virtuoso
        ref={listRef}
        style={{
          height: "100%",
          width: '100%'
        }}
        className='no-scrollbar'
        totalCount={formItems.length}
        itemContent={renderVariable}
        computeItemKey={(index) => {
          return `${formItems[index].containerId}-${index}`
        }}
        components={{ List }}
      />
    </div>
  )
}

export default FormViewer
