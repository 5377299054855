import React from 'react'
import FormBoxName from './FormBoxName'
import LabelsArray from '../LabelsArray'
import SelectionMiddle from '../SelectionMiddle'
import FormBoxRemark from './FormBoxRemark'
import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../store/store'

const FormCheckboxInfos = ({ box, onBlur, form, updateBoxFn }) => {
  const { t } = useTranslation();
  const configuration = useStore(({ configuration }) => configuration)

  return (
    <>
      <FormBoxName box={box} onBlur={onBlur} form={form} />
      <SelectionMiddle
        box={box}
        onBlur={onBlur}
        form={form}
        arrayKey="checkboxs"
        selectionKey="checked"
      />
      <Form.Item
        label={t('checkbox-style')}
        name="checkboxStyleId"
        initialValue={box?.checkboxStyleId ?? configuration.checkboxStyles[0].id}
      >
        <Select
          onSelect={onBlur}
          options={configuration.checkboxStyles.map(style => ({ value: style.id, label: style.name }))}
        />
      </Form.Item>
      <FormBoxRemark box={box} onBlur={onBlur} form={form} />

      <LabelsArray
        box={box}
        onBlur={onBlur}
        form={form}
        arrayKey="checkboxs"
        selectionKey="checked"
        updateBoxFn={updateBoxFn}
      />
    </>
  )
}

export default FormCheckboxInfos
