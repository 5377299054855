import React from 'react';
import './LeftSide.less';
import OverlaySider from '../Layout/OverlaySider';
import { navbarHeight } from '../../constants/gridConfig';
import SummarySide from './SummarySide';
import { useStore } from '../../store/store';
import { pageBackground, } from '../../constants/constants';
import VariableHandler from '../VariableHandler/VariableHandler';
import { useOverlay } from '../../contexts/OverlayContext';
import { getVariablesGroupedByContainer } from '../../utils/variables';
import LeftSideButton from './LeftSideButton';

const LeftSide = () => {
  const variablesGroupedByContainer = useStore(({
    variableGroups,
    variables,
    boxes,
    masks,
    containers,
    columns
  }) => getVariablesGroupedByContainer({
    variableGroups,
    variables,
    boxes: [
      ...boxes,
      ...masks.map((m) => m.boxes).flat()
    ],
    containers,
    columns,
  }))

  const { overlayProps } = useOverlay();

  const shownStatus = overlayProps.left.shownStatus

  const onlyButton = shownStatus === 'variables'
  return (
    <>
      <OverlaySider
        side='left'
        zIndex={18}
        onlyButton={onlyButton}
      >
        <div
          className='left-side__summary'
          style={{
            height: onlyButton ? undefined : `calc(100vh - ${navbarHeight}px)`,
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
          data-role={pageBackground}
        >
          <LeftSideButton variablesGroupedByContainer={variablesGroupedByContainer} />
          {shownStatus === "summary" &&
            <div className='left-side__wrapper'>
              <SummarySide />
            </div>
          }
        </div>
      </OverlaySider >
      {shownStatus === "variables" &&
        <VariableHandler variablesGroupedByContainer={variablesGroupedByContainer} />
      }
    </>
  );
};

export default React.memo(LeftSide);
