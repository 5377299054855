import React from 'react'
import { useStore } from '../../../store/store';
import { mutateInjectVariablesInDocument } from '../../../utils/variables';
import ButtonRefresh from '../../common/ButtonRefresh';

const ItemRefresher = ({ variableIds }) => {
  const updateVariables = useStore(({ updateVariables }) => updateVariables)

  const handleOnClickRefresh = () => {
    updateVariables(variableIds, (variablesToUpdate, draft) => {
      variablesToUpdate.forEach((variable, idx) => {
        const keys = variableIds[idx].split('.')
        const categoryKey = keys.length === 3 ? keys[1] : keys[0]
        const informationKey = keys.length === 3 ? keys[2] : keys[1]
        let value;
        if (keys.length === 3) {
          const indexKey = draft.defaultVariablesApi.opportunities_variables.findIndex((opp) => opp[Object.keys(opp)[0]].opportunity.CHRONO === keys[0])
          const opportunityKey = Object.keys(draft.defaultVariablesApi.opportunities_variables[indexKey])[0]
          value = draft.defaultVariablesApi.opportunities_variables[indexKey][opportunityKey][categoryKey][informationKey] ?? ''
        } else if (categoryKey === 'custom') {
          value = draft.defaultVariablesApi.custom_text_variables[`${variable.name}.${informationKey}`] ?? ''
        }
        else if (draft.defaultVariablesApi.offer_variables) {
          value = draft.defaultVariablesApi.offer_variables[categoryKey][informationKey] ?? ''
        } else {
          value = draft.defaultVariablesApi[categoryKey][informationKey]
        }
        variablesToUpdate[idx].value = value
        mutateInjectVariablesInDocument({
          variables: draft.variables,
          variable: variablesToUpdate[idx],
          variableKey: variableIds[idx],
          draft
        })
      })
    })
  }

  return (
    <div style={{
      marginLeft: 6
    }}>
      <ButtonRefresh handleOnClickRefresh={handleOnClickRefresh} />
    </div>
  )
}

export default ItemRefresher
