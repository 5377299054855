import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Popover, Switch } from 'antd';
import { useStore } from '../../../store/store';
import { ItemTypes, pageBackground, tableHeaderSecondaryBgColor } from '../../../constants/constants';
import { mutateInitializeResult } from '../../../utils/triggers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useOutsideClickHandler } from '../../../utils/browser';
import FormSectionBox from './FormSectionBox';
import CustomTextVariable from '../FormViewer/CustomTextVariable';
import SimulatedBox from '../../common/SimulatedBox';

const TriggerResultWrapper = ({ box, result, record, setIsPopoverOpen, isSelected, setIsSelected }) => {
  const updateTrigger = useStore(({ updateTrigger }) => updateTrigger)
  const ref = useRef()
  useOutsideClickHandler(ref, () => {
    setIsPopoverOpen(false)
  });

  const updateVariableFn = useCallback((parentId, resultCallback) => {
    updateTrigger(record.id, parentId, { resultCallback })
  }, [record.id, updateTrigger])

  return <div style={{
    width: 400,
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  }}
    onClick={() => setIsSelected(false)}
    data-role={pageBackground}
    ref={ref}>
    {(box.type === ItemTypes.TEXT_VARIABLE || box.type === ItemTypes.CHECKBOXS_VARIABLE || box.type === ItemTypes.QUESTION
      || box.type === ItemTypes.SELECTOR_VARIABLE || box.type === ItemTypes.FILE_PDF_VARIABLE || box.type === ItemTypes.SECTION_VARIABLE
    ) && <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: 8
    }}>
        Masquer
        <Switch checked={result?.hideField} onChange={() => {
          updateTrigger(record.id, box.id, {
            resultCallback: (r, trigger) => {
              const hideField = !r.hideField
              if (hideField) {
                for (var variableKey in r) {
                  if (r.hasOwnProperty(variableKey) && variableKey !== "hideField") {
                    delete r[variableKey];
                  }
                }
              }
              else {
                mutateInitializeResult({ parent: box, trigger });
              }
              r.hideField = !r.hideField
            }
          })
        }} /></div>}

    {!result.hideField &&
      box.type !== ItemTypes.QUESTION &&
      box.type !== ItemTypes.SECTION_VARIABLE &&
      box.type !== ItemTypes.CUSTOM_TEXT_VARIABLE &&
      box.type !== ItemTypes.FILE_PDF_VARIABLE &&
      <TriggerResultBox setIsPopoverOpen={setIsPopoverOpen} box={box} result={result} record={record} isSelected={isSelected} setIsSelected={setIsSelected} />}

    {!result.hideField && box.type === ItemTypes.SECTION_VARIABLE && <FormSectionBox onSelect={(e) => updateTrigger(record.id, box.id, {
      resultCallback: (r) => {
        r.sectionId = e
      }
    })} value={result.sectionId} />}

    {box.type === ItemTypes.CUSTOM_TEXT_VARIABLE && <CustomTextVariable variableKey={box.id} updateVariableFn={updateVariableFn} isBoxSimulated variableValue={result.value} />}
  </div>
}

const TriggerResultBox = ({ box, result, record, isSelected, setIsSelected }) => {
  const updateTrigger = useStore(({ updateTrigger }) => updateTrigger)

  const commonBox = useMemo(() => ({
    ...box,
    width: "100%",
    isBoxSimulated: true,
    isSelected,
    parentId: box.id,
    id: record.id
  }), [box, record.id, isSelected])

  const updateBox = useCallback((triggerId, resultCallback) => {
    updateTrigger(triggerId, box.id, {
      resultCallback: (result, trigger) => {
        if (!result.hideField) {
          if (box.type === ItemTypes.CHECKBOXS_VARIABLE || box.type === ItemTypes.QUESTION) {
            result.checkboxs = box.checkboxs.map(({ id }) => {
              return {
                id,
                checked: result.checkboxs.some(({ id: cId, checked }) => cId === id && checked)
              }
            })
          }
          if (box.type === ItemTypes.SELECTOR_VARIABLE) {
            result.options = box.options.map(({ id }) => {
              return {
                id,
                selected: result.options.some(({ id: cId, selected }) => cId === id && selected)
              }
            })
          }
        }
        resultCallback(result, trigger)
      }
    })
  }, [box.checkboxs, box.id, box.options, box.type, updateTrigger])

  const formattedResult = useMemo(() => {
    const res = { ...result };
    if (box.type === ItemTypes.CHECKBOXS_VARIABLE || box.type === ItemTypes.QUESTION) {
      res.checkboxs = commonBox.checkboxs.map((c) => ({
        ...c,
        checked: res.checkboxs.some(({ id, checked }) => c.id === id && checked)
      }))
    }
    if (box.type === ItemTypes.SELECTOR_VARIABLE) {
      res.options = commonBox.options.map((c) => ({
        ...c,
        selected: res.options.some(({ id, selected }) => c.id === id && selected)
      }))
    }
    return res
  }, [box.type, commonBox.checkboxs, commonBox.options, result]);

  const boxToSend = useMemo(() => {
    return { ...commonBox, ...formattedResult, updateBox }
  }, [commonBox, formattedResult, updateBox])

  return <SimulatedBox box={boxToSend} isSelected={isSelected} setIsSelected={setIsSelected} />
}

const TriggerResultPopover = ({ box, result, record }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const handleUnSelectBox = useStore(({ handleUnSelectBox }) => handleUnSelectBox)

  return (
    <div style={{
      display: 'flex',
      width: "100%"
    }}>
      <Popover
        open={isPopoverOpen}
        destroyTooltipOnHide
        zIndex={9997}
        trigger="click"
        placement='bottom'
        arrow
        overlayInnerStyle={{
          margin: 0,
          padding: 0
        }}
        content={<TriggerResultWrapper setIsPopoverOpen={setIsPopoverOpen} box={box} result={result} record={record} isSelected={isSelected} setIsSelected={setIsSelected} />}
      >
        <div style={{
          width: "100%",
          height: "100%",
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center'
        }}
          data-role={pageBackground}
          onClick={() => {
            handleUnSelectBox();
            setIsSelected(false)
            setIsPopoverOpen(true)
          }} >
          < FontAwesomeIcon color={tableHeaderSecondaryBgColor} data-role={pageBackground} icon={icon({ name: 'pen-to-square', style: 'duotone' })}
            style={{
              margin: 0,
              fontSize: 15,
            }}
          />
        </div>
      </Popover>
    </div>
  )
}

export default TriggerResultPopover
