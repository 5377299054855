import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStore } from './store/store';
import { useMutation, useQuery } from 'react-query';
import { getDocumentTypes } from './requests/documentTypes';
import { getProposalSections, getTemplateSections } from './requests/sections';
import {
  createCustomTextVariable,
  updateCustomTextVariable,
} from './requests/customTextVariable';
import {
  generateColorsObject,
  generateFamilyObject,
  generateFontSizesObject,
  generateHighlightObject,
  MASKS_TYPE,
  viewerTypes,
} from './constants/constants';
import {
  createVariableGroup,
  updateVariableGroup,
} from './requests/variableGroup';
import { isASelectionBox, isAVariableBox } from './utils/boxes';
import { shouldHideSectionVariable } from './utils/sections';

export const useOutsideAlerter = (ref, stateChange) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        stateChange(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, stateChange]);
};

const selector = (state) => ({
  setEventManagerEnabled: state.setEventManagerEnabled,
});

export const useModalOpener = (defaultValue) => {
  const [isOpen, setIsOpen] = useState(defaultValue);
  const { setEventManagerEnabled } = useStore(selector);

  const toggleModal = (opened) => {
    setIsOpen(opened);
    setEventManagerEnabled(!opened);
  };

  return [isOpen, toggleModal];
};

export const useKeyboard = (defaultValue) => {
  const { setEventManagerEnabled } = useStore(selector);

  const setIsUsingKeyboard = (isUsing) => {
    setEventManagerEnabled(!isUsing);
  };

  return setIsUsingKeyboard;
};

export const useUpdateBoxOnUnselect = ({ boxId, isSelected, updateBox }) => {
  const wasLastSelected = useRef(false);
  useEffect(() => {
    if (isSelected) {
      wasLastSelected.current = true;
    }
    if (!isSelected && wasLastSelected.current === true && boxId) {
      wasLastSelected.current = false;
      updateBox(boxId);
    }
  }, [boxId, isSelected, updateBox]);
};

export const useQueryDocumentTypes = (options = {}) =>
  useQuery(['DocumentTypes'], getDocumentTypes, {
    refetchOnWindowFocus: false,
    placeholderData: [],
    ...options,
  });

export const useQueryDocumentSections = (
  { documentType, documentId },
  options = {}
) => {
  const fetchFn =
    documentType === 'template' ? getTemplateSections : getProposalSections;
  return useQuery(
    ['Sections', { documentType, documentId }],
    () => fetchFn(documentId),
    {
      refetchOnWindowFocus: false,
      enabled: !!documentId && !!documentType,
      ...options,
    }
  );
};

export const useCreateCustomTextVariable = (options = {}) => {
  return useMutation(({ name }) => createCustomTextVariable({ name }), {
    ...options,
  });
};

export const useUpdateCustomTextVariable = (options = {}) => {
  return useMutation(({ name, id }) => updateCustomTextVariable({ id, name }), {
    ...options,
  });
};

export const useCreateVariableGroup = (options = {}) => {
  return useMutation(
    ({ name, remark, disposition }) =>
      createVariableGroup({ name, remark, disposition }),
    {
      ...options,
    }
  );
};

export const useUpdateVariableGroup = (options = {}) => {
  return useMutation(
    ({ name, remark, variableIds, id, disposition }) =>
      updateVariableGroup({
        name,
        remark,
        variableIds,
        id,
        disposition,
      }),
    {
      ...options,
    }
  );
};

export const useStyleMap = () => {
  const configuration = useStore((state) => state.configuration);
  const fromPdf = useStore((state) => state.fromPdf);
  const createStyleMap = useCallback(
    () => ({
      CODE: {
        backgroundColor: 'rgba(0, 0, 0, 1.0)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
      },
      BOLD: {
        fontWeight: 1000,
      },
      HIGHLIGHT: {
        backgroundColor: configuration.currentHighlightColor.style,
      },
      ...generateHighlightObject(configuration.highlightColors),
      ...generateFontSizesObject(),
      ...generateColorsObject(configuration.colors),
      ...generateFamilyObject(configuration.fontFamilyList, fromPdf),
    }),
    [configuration, fromPdf]
  );
  const [styleMap, setStyleMap] = useState(createStyleMap());

  useEffect(() => {
    setStyleMap(createStyleMap);
  }, [createStyleMap]);
  return styleMap;
};

export const useMenuPosition = (ref) => {
  const [menuPosition, setMenuPosition] = useState({ top: null, left: null });

  useEffect(() => {
    const updateTopPosition = () => {
      if (ref.current) {
        const { top, left } = ref.current.getBoundingClientRect();
        setMenuPosition({ top: top - 50, left: left + 200 });
      }
    };

    // Initial top position update
    updateTopPosition();

    // Create a ResizeObserver to watch for changes
    const observer = new ResizeObserver(updateTopPosition);
    observer.observe(ref.current);

    // Optionally, you can also track scrolling
    window.addEventListener('scroll', updateTopPosition);

    // Cleanup on unmount
    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', updateTopPosition);
    };
  }, [ref]);

  return menuPosition;
};

export const useHandleBoxSelect = ({ fromViewer, box }) => {
  const currentProposal = useStore(({ currentProposal }) => currentProposal);
  const isSelected = useStore(({ selectedBoxId }) => selectedBoxId === box.id);
  const handleSelectBox = useStore(({ handleSelectBox }) => handleSelectBox);
  const container = useStore(({ containers }) =>
    containers.find((container) => container.columnsIds.includes(box.columnId))
  );
  const maskType = useStore(
    ({ masks }) =>
      masks.find((m) => m.id === MASKS_TYPE.HEADER.id)?.editableMask
  );
  const maskTypeEditable = typeof maskType !== 'boolean' ? true : maskType;
  return useCallback(
    (event) => {
      const group = event.ctrlKey || event.metaKey;
      if (fromViewer === viewerTypes.PAGENUMBER) return;
      if (
        (box.maskId === 'header' || box.maskId === 'footer') &&
        !maskTypeEditable &&
        box.type !== 'img'
      ) {
        return null;
      }
      if (currentProposal.id === null && container?.from_linked_section)
        return null;
      if (
        container?.editable ||
        !container?.hasOwnProperty('editable') ||
        currentProposal.id === null
      ) {
        if (!isSelected) {
          handleSelectBox({ box, group });
        }
      } else {
        if (box.type === 'img' || isAVariableBox(box.type)) {
          if (!isSelected) {
            handleSelectBox({ box, group });
          }
        }
      }
    },
    [
      fromViewer,
      box,
      maskTypeEditable,
      currentProposal.id,
      container,
      isSelected,
      handleSelectBox,
    ]
  );
};

export const useContainersToShow = () => {
  const boxes = useStore(({ boxes }) => boxes);
  const columns = useStore(({ columns }) => columns);
  const containers = useStore(({ containers }) => containers);

  const documentId = useStore(({ currentDocument, currentProposal }) =>
    currentDocument.type === 'template'
      ? currentDocument.id
      : currentProposal.id
  );
  const documentType = useStore(({ currentDocument }) => currentDocument.type);

  const { data: sectionList } = useQueryDocumentSections({
    documentType,
    documentId,
  });

  const containersToShow = useMemo(() => {
    // Créer une map pour relier columnId au type de box correspondante
    const boxMap = new Map(
      boxes
        .filter(
          (b) =>
            b.hideField ||
            (documentType !== 'template' &&
              shouldHideSectionVariable({
                type: b.type,
                sectionId: b.sectionId,
                sectionList,
                isActivatedByTrigger: b.isActivatedByTrigger,
              }))
        )
        .map((b) => [b.columnId, b.type])
    );

    // Trouver les colonnes à masquer en se basant sur columnId
    const columnsToHide = columns.filter(({ id, drawMode }) => {
      return boxMap.has(id) && (!drawMode || isASelectionBox(boxMap.get(id)));
    });

    return containers.filter(
      (container) =>
        columnsToHide.filter(({ id }) => container.columnsIds.includes(id))
          .length !== container.columnsIds.length
    );
  }, [boxes, columns, containers, documentType, sectionList]);

  return containersToShow;
};
