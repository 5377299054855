import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// Common
// Others
import { Editor, EditorState, Modifier } from 'draft-js';
import { useStore } from '../../store/store';
import { displayTitleIndex, getTitlesConfig } from '../../utils/titles';
import { getTitleStyle } from './titleUtils';
import "./Title.css"
import { debounce } from 'lodash';
import ReactDOM from 'react-dom';
import { decorator, handleBeforeInput } from '../RichEditor/utils';
import ContextualMenu from '../ContextualMenu/ContextualMenu';
import { ItemTypes, MENU_ANCESTORS, TITLES } from '../../constants/constants';
import { EditorProvider } from '../../contexts/EditorContext';
import NavbarSelector from '../Layout/NavbarSelector';
import { calculateHeight, calculateLeftPadding, calculateWidth } from '../../constants/gridConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { isATitleBox } from '../../utils/boxes';
import { Tooltip } from 'antd';
import { NavbarButton } from '../Layout/NavbarButton';
import { iconButtonStyle } from '../../utils/styles';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { Separator } from '../Layout/Navbar';
// Store selector
const selector = (id, type) => (state) => ({
  updateBox: state.updateBox,
  variables: state.opportunitieVariables,
  configuration: state.configuration,
  configurationType: state.configuration[type],
  setBoxMovable: state.setBoxMovable,
  separator: state.configuration.separatorTitle,
  gridConfiguration: state.gridConfiguration,
  titleTypeConfig: getTitlesConfig(state.configuration),
  isTemplate: state.currentDocument.type === 'template',
  setEventManagerEnabled: state.setEventManagerEnabled,
  isSelected: state.selectedBoxId === id,
  state,
  boxes: state.boxes,
  fromPdf: state.fromPdf
});

const draft = () => (state) => ({
  updateIndexTitle: state.updateIndexTitle,
  updateBox: state.updateBox,
  defaultConfiguration: state.configuration,
});

const Title = ({ box, drawMode }) => {
  const editorRef = useRef(null);
  const boundingBoxRef = useRef(null);
  const {
    updateBox,
    configuration,
    configurationType,
    separator,
    setBoxMovable,
    titleTypeConfig,
    gridConfiguration,
    setEventManagerEnabled,
    isSelected,
    state,
    boxes,
    fromPdf
  } = useStore(selector(box.id, box.type));
  const [editorState, setEditorState] = useState(EditorState.createWithContent(
    box.content.editorState.getCurrentContent(),
    decorator
  ));

  const [defaultSelection, setDefaultSelection] = useState(null);
  const saveBox = useCallback(debounce((editorState) => {
    if (boundingBoxRef.current) {
      updateBox(box.id, (box) => {
        box.height = boundingBoxRef?.current.firstChild.clientHeight;
        box.content.editorState = EditorState.createWithContent(
          editorState.getCurrentContent(),
          decorator
        );
      });
    }
  }, 100), [box?.id, updateBox]);

  useEffect(() => {
    if (isSelected) {
      saveBox(editorState)
    }
  }, [isSelected, saveBox, editorState]);

  const {
    color: { style: color },
    ...rest
  } = configurationType;


  const write = (editorState) => {
    setEditorState(editorState);
  };


  const fontSizeTitle = configuration[box.type].fontSize;
  const paddingTitle = gridConfiguration[box.type].paddingTitle;

  // If in default container, focus on one click
  useEffect(() => {
    if (!drawMode && isSelected) {
      editorRef.current.focus();
    }
  }, [drawMode, isSelected])

  const menuAncestorIds = useMemo(() => [box.id], [box.id])

  return (
    <EditorProvider setEditorState={setEditorState} editorState={editorState} readOnly={!isSelected}>
      <div
        ref={boundingBoxRef}
        id={box.id}
        style={{
          ...getTitleStyle(
            configuration[box.type].backgroundColor,
            configuration[box.type].borders,
            configuration[box.type],
            color,
            rest
          ),
          width: box.width,
          marginTop: configuration[box.type].marginTop,
          marginBottom: configuration[box.type].marginBottom,
        }}
        onKeyDown={(e) => {
          if (editorState.getSelection().getHasFocus())
            e.stopPropagation()
        }}
      >
        <div
          style={{ paddingRight: paddingTitle, fontSize: fontSizeTitle, width: 'auto', flexShrink: 0, display: 'flex' }}
        >
          {!fromPdf && <div class="container-collapse">
            <Tooltip title={box?.collapsed ? "Déplier le titre" : "Réduire le titre"} placement="bottom">
              <FontAwesomeIcon id="collapsed" icon={faChevronDown}
                style={{ transform: box.collapsed ? "rotate(-90deg)" : "rotate(0deg)" }} onClick={() => updateBox(box.id, (box) => {
                  box.collapsed = !box?.collapsed;
                })} />
            </Tooltip>
          </div>}


          {displayTitleIndex(
            box.content.indexTitle,
            separator,
            titleTypeConfig,
            configuration.hideTitleIndex
          )}
        </div>
        <Editor
          stripPastedStyles={true}
          handleBeforeInput={(chars, editorState) => handleBeforeInput(
            {
              chars,
              editorState,
              setEditorState
            }
          )}
          editorState={editorState}
          onBlur={() => saveBox(editorState)}
          onChange={write}
          spellCheck={true}
          ref={editorRef}
          onFocus={(event) => {
            setBoxMovable(false);
            setEventManagerEnabled(false);
          }}
          readOnly={!isSelected}
        />
        {isSelected &&
          ReactDOM.createPortal(
            <ContextualMenu
              editorState={editorState}
              setEditorState={setEditorState}
              menuAncestorType={MENU_ANCESTORS.BOX.content}
              menuAncestorIds={menuAncestorIds}
              defaultSelection={defaultSelection}
              setDefaultSelection={setDefaultSelection}
            />,
            document.getElementById('SelectionVariablePortal')
          )}
        {
          isSelected && ReactDOM.createPortal(
            <TitleComponent selectedBox={box} state={state}
              boxes={boxes}
              editorState={editorState}
              setEditorState={setEditorState}
              setDefaultSelection={setDefaultSelection}
            />,
            document.getElementById('ComponentPortal')
          )
        }
      </div>
    </EditorProvider>
  );
};

const TitleComponent = ({ selectedBox, boxes, editorState, setEditorState, setDefaultSelection }) => {

  const {
    updateIndexTitle,
    updateBox,
    defaultConfiguration
  } = useStore(draft());
  const { t } = useTranslation();
  const boxId = selectedBox?.id;
  const selectLevelTitle = (titleId) => {
    const titleIndices = {
      [ItemTypes.TITLE]: 0,
      [ItemTypes.TITLE_2]: 1,
      [ItemTypes.TITLE_3]: 2,
      [ItemTypes.TITLE_4]: 3,
    };
    const titleType = {
      0: ItemTypes.TITLE,
      1: ItemTypes.TITLE_2,
      2: ItemTypes.TITLE_3,
      3: ItemTypes.TITLE_4,
    }
    let indexPositionTitle = titleIndices[titleId] ?? -1;

    const width = calculateWidth(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      indexPositionTitle
    );
    const left = calculateLeftPadding(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      indexPositionTitle
    )
    const valueIncrementation = titleIndices[selectedBox.type] > indexPositionTitle ? -1 : titleIndices[titleId];

    boxes.forEach((boxChild) => {
      if (!isATitleBox(boxChild.type)) return;
      if (boxId === boxChild.id) return;

      const content = boxChild.content.indexTitle.slice(0, selectedBox.content.indexTitle.length);

      if (selectedBox.content.indexTitle.join(',') === content.join(',')) {
        const newLevelType = titleType?.[titleIndices[boxChild.type] + (valueIncrementation)] || ItemTypes.TITLE_4
        const titleLevelIndexFactor = titleIndices[titleType?.[titleIndices[boxChild.type] + (valueIncrementation)] || ItemTypes.TITLE_4]
        const widthChildTitle = calculateWidth(
          defaultConfiguration.margins,
          defaultConfiguration.indentationTitles,
          titleLevelIndexFactor
        );
        const leftChildTittle = calculateLeftPadding(
          defaultConfiguration.margins,
          defaultConfiguration.indentationTitles,
          titleLevelIndexFactor
        );
        updateBox(boxChild.id, (box) => {
          box.content.indexTitle = [1, 0, 1, 1]
          box.type = newLevelType;
          box.clientWidth = widthChildTitle
          box.clientHeight = calculateHeight();
          box.left = leftChildTittle
          box.width = widthChildTitle
          box.height = calculateHeight();
        });
      }
    }
    );

    updateBox(boxId, (box) => {
      box.type = titleId;
      box.clientWidth = width
      box.clientHeight = calculateHeight();
      box.left = left
      box.width = width
      box.height = calculateHeight();
    });

    updateIndexTitle()
  };

  const addAtInContentTextRichEditor = (editorState, content) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      content
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters'
    );
    return newEditorState;
  };

  const handleAddAt = () => {
    const newEditorState = addAtInContentTextRichEditor(editorState, ' @');
    setEditorState(newEditorState);
  };

  const handleAddImageVariable = () => {
    const newEditorState = addAtInContentTextRichEditor(editorState, ' /');
    setDefaultSelection("img")
    setEditorState(newEditorState);
  };
  return (
    <div className='navbar__formshape-properties-container'>
      <div className='navbar__fstop-properties-container'>
        <NavbarButton
          tooltipContent={t('AJOUTER_UNE_VARIABLE')}
          onClick={() => handleAddAt()}

          icon={< FontAwesomeIcon icon={icon({ name: 'at', style: 'light' })} style={iconButtonStyle()} />}
        />
        <NavbarButton
          tooltipContent={t('IMAGE_VARIABLE')}
          onClick={() => handleAddImageVariable()}
          icon={< FontAwesomeIcon icon={icon({ name: 'image', style: 'light' })} style={iconButtonStyle()} />}
        />
        <Separator />
        <NavbarSelector
          tooltipContent={'Niveau des titres'}
          // disabled={selectedBox?.type !== 'shape'}
          // suffixIcon={< FontAwesomeIcon icon={icon({ name: 'vector-polygon', style: 'light' })} />}
          onSelect={selectLevelTitle}
          selectedValue={selectedBox.type}
          options={TITLES.map(({ id, name }) => ({ label: name, value: id }))}
        />
      </div>
    </div>
  );
};

export default Title;
