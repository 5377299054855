import React, { useCallback } from 'react'
import { useStore } from '../../store/store';
import AdderColumns from './AdderColumns';
import { ItemTypes, MASKS_TYPE, containerBackground, contentHeight, defaultItemValues, defaultZoom, pageBackground } from '../../constants/constants';
import { gridUnit } from '../../constants/gridConfig';
import { initializeBoxContent, isADrawBox, isATitleBox, reinitializeBoxWidthAndHeight } from '../../utils/boxes';
import { computeColumnWidth } from '../../utils/columns';
import { useDrop } from 'react-dnd';
import ContainerWrapper from './ContainerWrapper';
import { scrollToItem } from '../Middle/InfinitePage';
import { notification } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

const accept = [
  'newBox',
  ItemTypes.CONTAINER_COPIED,
  ItemTypes.IMG,
  ItemTypes.TEXT,
  ItemTypes.SHAPE,
  ItemTypes.LINE,
  ItemTypes.TABLE,
  ItemTypes.TITLE,
  ItemTypes.TITLE_2,
  ItemTypes.TITLE_3,
  ItemTypes.TITLE_4,
  ItemTypes.SUMMARY,
  ItemTypes.MASK,
  ItemTypes.CHECKBOXS_VARIABLE,
  ItemTypes.SELECTOR_VARIABLE,
  ItemTypes.TEXT_VARIABLE,
  ItemTypes.IMG_VARIABLE,
  ItemTypes.TABLE_VARIABLE
];

const selector = ({
  boxes,
  configuration,
  addContainers,
  containers,
  landscape,
  unCollapseTitles,
}) => ({
  containers,
  addContainers,
  configuration,
  landscape,
  marginLeft: configuration.margins.left * gridUnit,
  marginRight: configuration.margins.right * gridUnit,
  titles: boxes.filter((box) => isATitleBox(box.type)),
  unCollapseTitles,
  boxes
});

const ItemWrapper = React.memo(({ index: windowItemsIndex, itemId, itemLength, type, titleCollapsed }) => {
  const {
    addContainers,
    configuration,
    marginLeft,
    marginRight,
    titles,
    containers,
    landscape,
    unCollapseTitles,
    boxes
  } = useStore(selector);
  const containerId = itemId

  const inside_linked_section = containers[containers.findIndex((c) => c.id === containerId) + 1]?.from_linked_section
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const unlockProposal = useStore(({ configuration }) => configuration.unlockProposal)
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')

  // const findBoxIdxByColumnId = useCallback((columnId) => {
  //   return boxes.findIndex((b) => b.columnId === columnId)
  // }, [boxes])

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message) => {
    api.warning({
      message: 'Ajout impossible',
      description: message,
      placement: 'top',
    });
  };

  const onDrop = ({ box: element, monitor, isFromAdder }) => {
    if (titleCollapsed) {
      openNotification(<div>Vous ne pouvez pas déposer d'élément dans un <b>titre non déplié</b></div>)
      return
    }
    if (inside_linked_section) {
      openNotification(<div>Vous ne pouvez pas déposer d'élément dans une <b>section liée</b></div>)
      return
    }
    // const container = containers.find((c) => c.id === itemId);
    // if (container) {
    //   const boxIdx = findBoxIdxByColumnId(container.columnsIds[0]);

    //   for (let i = boxIdx; i >= 0; i--) {
    //     const b = boxes[i];
    //     if (isATitleBox(b.type) && b.collapsed === true) {
    //       unCollapseTitles({ index: b.content.indexTitle[0] });
    //       scrollToItem(boxes[boxIdx], containers);
    //       break;
    //     }
    //   }
    // }

    if (monitor.getItemType() === ItemTypes.CONTAINER_COPIED) {
      let index;
      if (isFromAdder) {
        if (windowItemsIndex === 0) { index = null } else {
          index = containers.findIndex((c) => c.id === containerId)
        }
      }
      let newBoxes = element.boxes.map((b) => initializeBoxContent({
        box: b,
        configuration,
        titles,
      }));

      addContainers({
        index,
        drawMode: element.drawMode,
        columnsSizes: element.drawMode ? [1] : element.boxes.map((b) => b.colSize),
        box: newBoxes.map(({ colSize, ...rest }) => rest),
        fromCopiedContainer: { container_height: element.copiedContainer.height }
      })
    }

    if (monitor.getItemType() === 'newBox') {
      let newBox = initializeBoxContent({
        box: element,
        configuration,
        titles,
      });

      const drawMode = isADrawBox(element.type);

      if (!drawMode) {
        newBox = reinitializeBoxWidthAndHeight({
          box: newBox,
          columnWidth: computeColumnWidth({
            landscape,
            marginRight,
            marginLeft
          }),
          onCreate: true,
          header,
          footer
        })
      }
      let index;
      if (isFromAdder) {
        if (windowItemsIndex === 0) { index = null } else {
          index = containers.findIndex((c) => c.id === containerId)
        }
      }
      addContainers({
        index,
        drawMode,
        box: newBox,
      })
    }
  }

  const boxCanDrop = useCallback((_, monitor) => {
    const box = monitor.getItem();
    if (landscape && !isADrawBox(box.type)) {
      return false;
    }
    // Can't move box from parent to another one
    if (box.parentId) {
      return false
    }
    if (!isTemplate && !unlockProposal) {
      return false
    }
    return true
  }, [unlockProposal, isTemplate, landscape])

  const [{ canDrop, boxIsOver }, dropAdder] = useDrop({
    accept,
    drop: (box, monitor) => onDrop({ box, monitor, isFromAdder: true }),
    canDrop: boxCanDrop,
    collect: (monitor) => ({
      boxIsOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop()
    }),
  });

  const [, dropInfinite] = useDrop({
    accept,
    canDrop: boxCanDrop,
    drop: (box, monitor) => onDrop({ box, monitor, isFromAdder: false })
  });

  const addTextBoxOnDoubleClick = useCallback(
    () => {
      if (landscape || (!isTemplate && !unlockProposal)) return;
      let newBox = initializeBoxContent({
        box: {
          ...defaultItemValues.text,
          type: 'text',
        },
        configuration,
        titles,
      });
      newBox = reinitializeBoxWidthAndHeight({
        box: newBox,
        columnWidth: computeColumnWidth({
          landscape,
          marginRight,
          marginLeft
        }),
        onCreate: true,
        header,
        footer
      })
      addContainers({ box: newBox });
    },
    [landscape, isTemplate, unlockProposal, configuration, titles, marginRight, marginLeft, header, footer, addContainers]
  );

  if (windowItemsIndex === 0) {
    return (
      <div data-role={pageBackground} data-parent={containerBackground}>
        <div ref={dropAdder}>
          <AdderColumns withoutContainers={itemLength === 1} boxIsOver={boxIsOver} canDrop={canDrop} />
        </div>
      </div>
    )
  }

  if (windowItemsIndex + 1 === itemLength) {
    return (
      <div data-role={pageBackground} data-parent={containerBackground}>
        <div
          id="infinite-page-drop"
          style={{
            width: "100%",
            height: contentHeight * (1 / ((configuration.zoom || defaultZoom) / 100)),
            backgroundColor: "white",
            position: 'relative'
          }}
          data-role={pageBackground}
          data-parent={containerBackground}
          onDoubleClick={addTextBoxOnDoubleClick}
          ref={dropInfinite}
        >
        </div>
      </div>
    )
  }

  if (!containers.some((c) => c.id === containerId)) return null

  return (
    <div data-role={pageBackground} data-parent={containerBackground}>
      {contextHolder}
      <ContainerWrapper
        containerId={containerId}
        type={type}
        titleCollapsed={titleCollapsed}
        fromLinkedSection={inside_linked_section}
        windowItemsIndex={windowItemsIndex}
        onDrop={onDrop}
        boxCanDrop={boxCanDrop} />
    </div>
  )
})

export default ItemWrapper
